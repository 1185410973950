.documentspost {
  width: 1450px;
  margin-left: 80px;
}
.appuploadresume {
  width: 340px;
  display: inline-block !important;
  padding: 18px 30px !important;
  box-shadow: none !important;
  border: 2px dashed var(--primary-bg-color) !important;
  border-radius: 10px !important;
  text-transform: inherit !important;
  font: normal normal normal 18px/24px "Roboto" !important;
  margin: 0 auto 20px !important;
  background-color: white !important;
  cursor: pointer !important;
  /* margin-top: -30px !important; */
  height: 64px !important;
}

.choosedocumentinput.MuiSelect-select:focus{
  background-color: rgb(255, 255, 255) !important;
}

.choosedocumentinput{
  text-align: left !important;
}

.appuploadresume:hover {
  background-color: #000000 !important;
  display: none;
  border: 2px solid #3b4046 !important;
}

.appuploadresume:hover span b:last-child {
  display: none;
}

.appuploadresume span:first-child {
  font-family: normal normal bold 18px "Roboto" !important;
  color: var(--primary-bg-color);
}

.appuploadresume span + span {
  display: none;
}
.applidocsupporttext {
  margin-top: -10px;
}

#documentViewer .sidebar {
  width: 790px !important;
}

#documentViewer .contentSection.pannelContent {
  height: inherit !important;
}

#documentViewer .contentSection.pannelContent article {
  margin: 0;
}

#menu-choosedocument .MuiMenu-paper {
  top: 235px!important;
}

.choosedocumentinput .MuiSelect-select	 {
  background-color: rgb(255, 255, 255) !important;
}

.uploadfileicon{
  margin-top: 40px;
}

.sidebar .actionBtns a{
  color: red !important;
  font-size: 14px !important;
}
