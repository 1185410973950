.addskills {
  margin-left: 80px;
  padding-bottom: 10px;
  margin-top: -55px;
}

.certi {
  margin-left: 80px;
  padding-bottom: 10px;
  margin-top: -55px;
}

.content-center img {
  margin: 0px !important;
}

.content-center {
  height: 33vh;
  top: 10%;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  margin: 0px !important;
  /* background-color: #eee; */
  align-content: center;
  line-height: 0.5;
}

.pulse i {
  color: #fff;
}

.pulse {
  height: 100px;
  width: 100px;
  background-color: var(--primary-bg-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  color: var(--primary-background-text-color);
}

.pulse::before {
  content: "";
  position: absolute;
  border: 1px solid var(--primary-bg-color);
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
}

.pulse::after {
  content: "";
  position: absolute;
  border: 1px solid var(--primary-bg-color);
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

.callconnectedtext {
  width: 535px;
  height: 30px;
  background: #F4FFEE 0% 0% no-repeat padding-box;
  border: 1px solid #29612F;
  opacity: 1;
  margin-top: 15px;
}

.callText {
  margin: 0px 15px;
  height: 31px;
  align-content: center;
  text-align: left;
  display: flex;
  align-items: center;
}

img.phone_Icon {
  height: 22px !important;
  width: 22px !important;
  margin: 0 10px 0 0 !important;
}

.phone_Calling_Img {
  height: 130px;
  width: 130px;
}


.call-button {
  margin-top: 50px;
}

.call-button svg {
  cursor: pointer;
  margin-bottom: 10px !important;
}

.call-connected {
  display: flex;
  width: 98%;
  height: 30px;
  background: #F4FFEE 0% 0% no-repeat padding-box;
  border: 1px solid #29612F;
  opacity: 1;
  gap: 20px;
  align-items: center;
  text-align: left !important;
  padding: 1%;
}
.call-initiated-text {
  display: flex;
  width: 96%;
  height: 30px;
  background: #fff padding-box;
  border: 1px solid #d1d1d1;
  border-top: none;
  opacity: 1;
  align-items: center;
  text-align: left !important;
  padding: 20px 10px 10px;
  margin-top: -2px;
}

.call-initiated-text p {
  letter-spacing: 0px;
  color: #797979;
  opacity: 1;
  font-size: 14px;
}

article img.phone-icon {
  margin: 0 0 0 10px !important;
}

.sucesses-text {
  margin: 0px;
}

.text-left {
  text-align: left;
}

.sidebar .cancel-btn {
  color: red !important;
  font-size: 14px;
}

p.callingtext {
  margin-top: 10%;
}
.cancelsave-btns {
  float: right;
}


div#cke_1_contents {
  height: 150px;
}
#callSection article {
  margin-top: 0px;
}
.MuiSelect-nativeInput{
  line-height: 20px;
}