.jobportalbody {
  background-color: #f4f4f4;
}

.jobportalheader {
  display: flex;
  gap: 20px;
  padding-top: 70px;
  margin-left: 60px;
}

.jobportaltext {
  font-family: "Roboto" !important;
  font-weight: 600;
  font-size: 19px;
  color: #1a1a1a;
  letter-spacing: 0px;
  margin-left: 20px;
}


.saveBtn1:hover {
  font-weight: 800;
}
.activatetext {
  color: #5d5d5d;
  font-family: "Roboto" !important;
}

.activateswitch {
  padding-top: 50px;
  margin-left: 1350px;
}

.question1 {
  color: #5d5d5d;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
}

.bigtextfeild {
  margin-left: 60px;
  padding-top: 30px;
}

.question1textboxes {
  display: flex;
  /* width: 100%!important; */
  justify-content: space-between;
  align-items: center;
}

.smalltextfeild {
  top: 0px;
  width: 140px;
}

.smalltextfeildview{
  margin-top: -10px;
}

.questionType > div {
  margin-left: auto;
  display: block;
}

.questionType {
  margin-left: auto;
  margin-right: -10px;
  width: 100%;
}
.questionType > div.validationright {
  position: relative;
  display: flex;
  margin-left: 275px;
}

.questionType .MuiInputBase-sizeSmall.smalltextfeild {
  width: 100%;
}

.mr-10 {
  margin-right: 25px;
}

.mr-5 {
  margin-right: 15px;
}

.pencilicon {
  margin-left: 895px;
  padding-top: 10px;
}

.circleplusicon {
  margin-left: 0px;
  margin: 0 auto 0 !important;
  height: 35px;
  width: 35px;
}

.addquestionicontext {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 0px !important;
}

.addQuestionBtnsContext {
  display: flex;
  align-items: center;
  margin-top: 0px !important;
}

.addquestion {
  color: #3b4046;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 18px;
}

/* .circleplusicon:hover {
  background-color: var(--primary-bg-color) !important;
  border-radius: 20px;
} */

.addquestionicontext > button {
  margin-left: 0 !important;
}
.p-1 {
  padding: 5px;
  margin: 5px;
}
.justify-content-end {
  justify-content: flex-end;
  margin-top: 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.mb-2 {
  margin-bottom: 10px;
  width: 300px;
}
.ml-5 {
  margin-left: 100px;
}
.ml-10 {
  margin-left: 700px;
  position: absolute;
  top: -95px;
  right: 45px;
}
.ml-11 {
  margin-left: 700px;
}
.srv-validation-message {
  color: #d32f2f;
  font-size: 14px;
  margin-left: 1px;
  margin-top: 7px;
}
.mt-2 {
  margin-top: 15px;
}
.nodatasection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;
}
.text-left {
  text-align: left;
}
button:hover {
  /* font-weight: normal !important; */
  cursor: pointer;
}
.autoScroll {
  display: inline-flex;
  width: 100%;
  flex-flow: wrap;
  gap: 0 60px;
}

.autoScroll > div {
  /* border: 1px solid #c4c4c4;
  border-radius: 4px; */
  width: 23.3%;
  height: 46px;
}

.autoScroll > div > div{
  margin-right: 7px;
}

.autoScroll {
  display: inline-flex;
  width: 100%;
  flex-flow: wrap;
  gap: 0 21px;
}

.vendor-icon {
  width: 21px;
  height: 21px;
}
.delete-icon:hover {
  filter: invert(0.5) sepia(1) saturate(500) hue-rotate(2deg);
}

.delete-icon,
.edit-icon {
  margin: 10px 0 0 0;
}
/* .input-option input:disabled{
  padding:0 14px!important;
} */

/* .input-option {
  padding: 0 14px !important;
  height: 50% !important; 
  overflow: auto !important;
} */
.input-option {
background-color: #fff !important;
}

.MuiOutlinedInput-root.Mui-disabled {
  background-color: #f7f7f7 !important;
}

.addquestionicontext:hover .addquestion
 {
  color: var(--primary-bg-color);
}

.css-htmsu4-MuiFormControl-root-MuiTextField-root .MuiInputBase-root {
  height: 100% !important;
  overflow: auto !important;
}

.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
  overflow: hidden !important;
  height: 0px;
}


.question1textboxes div:first-child .srv-validation-message{
  margin-top: 5px;
}

.cursor-pointer > span{
   font-family: "Roboto";
   font-weight: 400 !important;
   color: #3B4046 !important;
}

.cursor-pointer:hover > span {
  color: var(--primary-bg-color) !important;
  font-family: "Roboto";
   font-weight: 400 !important;
}

.delete-icon, .edit-icon{
  margin: -8px 20px 0 0px!important;
}

.mt-2{
  margin-top: 20px;
}

.justify-content-between{
  justify-content: space-between;
}

.question1textboxes label.Option{
  padding: 10px 7px!important;
  display: block!important;
}

.sidebar .autoScroll > div {
  border: transparent!important;
  padding: 0!important;
  width: inherit;
  margin-bottom: 20px;
  height: inherit;
}

.sidebar .autoScroll{
  margin-top: 20px;
  max-height: calc(100vh - 565px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 23px;
  padding-top: 5px;
}

.jobPortal h4 {
  margin: 0 0 14px;
}

.jobPortal {
  border-radius: 5px;
  padding: 17px 18px;
  margin-bottom: 23px;
  background: #f5f5f5cf;
  box-shadow: 1px 1px 5px #d6d6d6;
}

.jobPortal input.Mui-disabled, .jobPortal select:disabled, .jobPortal .MuiInputBase-input.Mui-disabled {
  background: none!important;
}

.jobPortal .MuiInputBase-input {
  background: #fff!important;
}

.cursor-pointer.addoption.disabled, .cursor-pointer.addoption.disabled button {
  cursor: not-allowed!important;
  pointer-events: none!important;
}

/* .question-bts {
  margin-top: 87px;
} */

 .sidebar a.cancel-btn, .addQuestionBtnsContext a.cancel-btn {
  color: #d32f2f !important ;
}
.items-alignment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.questionsRightsidepanel footer img {
  width: 100%;
  margin-left: 0px;
  margin-bottom: -7px;
}

.questionsRightsidepanel .pannelContent{
  height: 85%;
}

.topBtn{
  position: absolute;
  bottom: 15% !important;
  left: 63%;
}