.dashboardSection {
    margin-top: 80px !important;
    padding: 22px 50px;
}

.dashboard_widgets {
    margin-bottom: 20px;
}

.dashboardSection .educationTermRangePicker .MuiTextField-root:first-child .MuiOutlinedInput-notchedOutline {
    border-right-color: #F6F6F6 !important;
    border-right-width: 4px;
}

.dashboardSection .educationTermRangePicker .MuiFormLabel-root {
    font-weight: bold;
    color: #000
}

.d-flex-around{
    display: flex;
    justify-content: space-between;
    align-items:center;
}

.date-time-container{
    display: flex;
    align-items: center;
    padding: 25px;
    margin-bottom: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.switch-container {
    min-width: 160px;
}

.table-widget,
.chart-widget{
    margin-bottom: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 19px;
    box-shadow: 0px 3px 6px #00000029;
}

.table-widget .d-flex-around:nth-child(1),
.chart-widget .d-flex-around:nth-child(1){
    margin-right: 30px
}

.chart-widget .MuiSwitch-root {
    width:60px;
    height: 40px;
}

.chart-widget .MuiSwitch-thumb {
    margin-top: 5px !important;
    background-color: var(--primary-bg-color) !important;
    color: var(--primary-background-text-color) !important;
}

.chart-widget .MuiSwitch-track,
.chart-widget .Mui-checked .MuiSwitch-track {
    background: #5D5D5D !important;
    opacity: 1 !important;
}

.no-data-container {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    width: 100%;
}

.piechart{
    padding: 40px 60px;
}

.table-widget {
    height: calc(100% - 20px);
}

.table-widget .table-container {
    padding: 25px;
}

.text{
    font-size: 16px;
}

.no-data{
    display: flex;
    justify-content: center;
}

.progress-bar{
    width: 67%;
    padding-left: 20px;
}

#table.dashboard_widgets {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 25px;
    margin-bottom: 20px;
    height: 773px;
}

 /* .customizedTable .MuiTableBody-root .MuiTableRow-root {
    height: 66px !important;
} */
.MuiTableCell-body {
    vertical-align: middle !important;
}

.dashboard_widgets aside {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.30000001192092896px solid #C3C3C3;
    border-radius: 19px;
    width: 100%;
    height: 351px;
}

.dashboard_widgets img {
    margin-right: 10px;
    height: 89px;
    width: 66px;
}

.widget-title {
    margin-bottom: 31px !important;
    margin-top:0px !important
}

.table-widget .d-flex-around label,
.chart-widget .d-flex-around label,
.dashboard_widgets aside label {
    width: 70%;
    height: 50px;
    background: var(--primary-bg-color) 0% 0% no-repeat padding-box;
    font: normal normal bold 18px / 24px Roboto;
    letter-spacing: 0px;
    color: var(--primary-background-text-color) !important;
    display: flex;
    align-items: center;
    border-top-left-radius: 19px;
    line-height: 1;
    padding-left: 21px;
    position: relative;
}

.table-widget .d-flex-around label:after,
.chart-widget .d-flex-around label:after,
.dashboard_widgets aside label:after {
    width: 26px;
    height: 50px;
    background: var(--primary-bg-color) 0% 0% no-repeat padding-box;
    content: "";
    right: -13px;
    position: absolute;
    transform: translate(0px, 0px) skew(-27deg, 0deg);
    color: var(--primary-background-text-color) !important;
}

.dashboard_widgets aside h1 {
    text-align: left;
    font: normal normal bold 70px/92px Roboto;
    letter-spacing: 0px;
    color: #404040;
    opacity: 1;
    margin: 0 0 0 20px;
}

.dashboard_widgets aside p {
    text-align: left;
    font: normal normal bold 16px/21px Roboto;;
    letter-spacing: 0px;
    color: #222222CC;
    opacity: 1;
    margin: 0;
    padding-left: 20px;
}
/* progressbar css */

.progressbarSection {
    padding-right: 10px;
    margin-bottom: 15px
}

.progressbarSection h3{
    text-align: right;
    font: normal normal bold 30px/39px Roboto;
    letter-spacing: 0px;
    margin: 0px 10px 0 0;
}

@media only screen and (max-width: 1600px) {
    .dashboard_widgets aside label {
        width: 68%;
    }

    .chart-widget .d-flex-around label {
        width: calc(100% - 210px);
    }

    .text {
        font-size: 14px;
    }

    .table-widget .d-flex-around label,
    .chart-widget .d-flex-around label,
    .dashboard_widgets aside label {
        font-size: 14px !important;
    }
}

