/* eslint-disable react/prop-types */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

/* for dynamic */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root {
  /* Primary Colors */
  --primary-bg-color: #FFD800;
  --secondary-bg-color: #3B4046;
  --danger-color: #FF0000;

  /* Text Colors */
  --primary-background-text-color: #3B4046;
  --secondary-background-text-color: #ffffff;

  /* Background Colors */
  --background-light: #ffffff;
  --background-dark: #f4f4f4;
  --background-highlight: #fffbe5;

  /* Font Family */
  --applications-font-family: "Roboto", sans-serif;
}

*,
p,
div,
li,
span,
small,
h1,
h2,
h3,
h4,
h5,
h6,
textarea,
table,
td,
th,
section,
article,
aside,
footer,
header,
nav {
  font-family: var(--applications-font-family) !important;
}

body {
  background-color: #f4f4f4;
  font-family: var(--applications-font-family) !important;
}

.customLogo {
  height: 60px;
}

/* SingUpLetter Css start here!*/
.lettercontainer {
  top: 3771px;
  left: -3px;
  width: 1924px;
  height: 426px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

/* * {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */

.signupfont {
  font-family: normal normal medium 35px/50px "Roboto";
  color: #454545;
  padding-top: 2.5rem;
  padding-left: 50rem;
  height: 49px;
  font-weight: bolder;
  padding-top: 8px;
}

/* SingUpLetter Css end here!*/
/* Career Advice Css start here!*/
.careercontainer {
  top: 2367px;
  left: 50px;
  width: 1821px;
  height: 491px;
  background: #fffbe5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

.heading {
  color: #000000;
  font-size: xx-large;
  font-weight: bolder;
  padding-top: 4rem;
}

.listli {
  font-size: medium;
  font-weight: bolder;
}

/* Career Advice Css end here!*/
/* CarouselPOne Css start here!*/
.carouseltext {
  top: 16rem;
  left: 18rem;
  width: 610px;
  height: 76px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  text-align: left;
  letter-spacing: 1.4px;
  color: #000000;
  opacity: 1;
  font-size: larger;
  font-weight: bolder;
  position: absolute;
}

.carouselwords {
  font-weight: 400;
  font-size: medium;
}

.NameCapitalise {
  text-transform: capitalize;
}

div {
  font-family: "Roboto";
}

/* CarouselPOne Css end here!*/
/* LatestJob Css start here!*/
.jobfont {
  display: flex;
  justify-content: center;
  color: #454545;
  opacity: 100%;
  padding-top: 15px;
}

.button {
  display: flex;
  justify-content: flex-end;
}

h1,
p {
  font-family: "Roboto";
}

.App {
  font-family: "Roboto";
  text-align: center;
}

.paginationBts {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBts a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid pink;
  color: lightblue;
  cursor: pointer;
}

.paginationBts a:hover {
  color: white;
  background-color: blue;
}

.paginationActive a {
  color: white;
  background-color: violet;
}

/* LatestJob Css end here!*/

/* Input Css start here!*/
.formcontainer {
  top: 639px;
  left: 0.2rem;
  width: 750px;
  height: 79px;
  margin-left: 20rem;
  margin-top: -12rem;
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.30000001192092896px solid #d1d1d1;
  border-radius: 67px;
  opacity: 1;
  position: absolute;
  border: 0;
}

input::placeholder {
  /*font-size: large;*/
  /*padding-left: 15rem;*/
}

input:hover::placeholder {
  /*color: red;*/
}

.searchbtn {
  position: absolute;
  margin-left: 32rem;
  margin-top: -38.5rem;
  border-radius: 50%;
  border: 0;
  background-color: #fafafa;
}

.jobseekerbtn {
  position: absolute;
  margin-left: -20rem;
  margin-top: -10rem;
  border-radius: 50%;
  border: 0;
  background-color: var(--primary-bg-color);
  color: var(--primary-background-text-color);
}

.interviewerbtn {
  position: absolute;
  margin-left: -8rem;
  margin-top: -10rem;
  background-color: var(--primary-bg-color);
  color: var(--primary-background-text-color);
  border: 0;
}

.interviewimg {
  width: 65%;
}

.employerbtn {
  position: absolute;
  margin-left: 5rem;
  margin-top: -10rem;
  background-color: var(--primary-bg-color);
  color: var(--primary-background-text-color);
  border: 0;
}

.formpost {
  top: 539px;
  left: 0.2rem;
  width: 1050px;
  height: 79px;
  margin-left: -8rem;
  margin-top: -12rem;
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px px white;
  border: 0.30000001192092896px solid #d1d1d1;
  border-radius: 67px;
  opacity: 1;
  position: absolute;
}

.formsubmitting {
  position: absolute;
  margin-left: 39rem;
  margin-top: 20.5rem;
  border-radius: 50%;
  border: 0;
  background-color: transparent;
}

.formpost {
  outline: none !important;
}

/* Input Css end here!*/

/* DropdownCountry Css start here!*/
.container {
  top: 0px;
  width: 1902px;
  height: 88px;
  background: var(--secondary-bg-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 0px 40px 40px;
  opacity: 1;
  position: "sticky";
  top: 0;
  left: 0;
}

/* DropdownCountry Css end here!*/

/* UserVerify Css start here!*/
.userverifylogin {
  position: absolute;
  margin-left: -14rem;
  margin-top: -32rem;
  border-radius: 50%;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.loginemp {
  top: 0%;
  background-color: #0a0a23;
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 15px;
  min-height: 30px;
  min-width: 140px;
}

/* UserVerify Css end here!*/

.headerBar {
  /* background: var(--secondary-bg-color) !important; */
  /* border-radius: 0 0 30px 30px; */
  height: 70px !important;
  z-index: 999 !important;
}

.headerDropdown .MuiMenu-paper {
  background-color: #fff !important;
  color: #000 !important;
}

.headerDropdown .MuiMenuItem-root {
  background-color: #fff !important;
  color: #000 !important;
}

.headerDropdown .MuiMenuItem-root:hover,
.headerDropdown .Mui-selected {
  background-color: #fff !important;
  color: #000 !important;
}

.navbarSection .MuiDrawer-paper {
  z-index: 998 !important;
}

.headerBar .MuiToolbar-regular {
  padding: 0 2.4% 0 46px;
}

.headerBar .MuiTypography-h6 img {
  width: 101px;
  margin-top: 10px;
  margin-right: 30px;
}

.headerBar img:hover,
.hirewingImg {
  cursor: pointer !important;
}

.headerBar .MuiButton-text a {
  color: #fff;
  text-decoration: none;
  text-transform: capitalize;
  margin: 0 7px;
}

.headerBar .MuiButton-text:hover a,
.headerBar .MuiButton-text:active a {
  font-weight: 500;
  color: var(--primary-bg-color);
}

.hirewingImg {
  width: 120px;
  margin-right: 21px;
  /* margin-top: 0px; */
  margin-top: -3px;
}

.dList {
  display: flex !important;
  padding: 3px 0px !important;
  margin-bottom: 1px !important;
  border-bottom: 1px solid #e8e8e8;
}

.dList p {
  min-width: 80px;
  margin-left: 18px;
  align-items: left;
}

.welcomeScreen {
  position: absolute;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.navbarList {
  margin-bottom: 10px;
  height: 50px;
}

.navbarList img {
  width: 27px;
  height: 21px;
  margin-right: 11px;
  padding-top: 8px;
}

.navbarList>div>div {
  min-width: 80%;
}
.navbarList>div {
  align-items: start;
}

::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: lightgray;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  cursor: pointer !important;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
  cursor: pointer !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #767676;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  cursor: pointer !important;
}

.navbarList>div {
  /* padding: 0 11px; */
  padding: 0px 0 0 10px;
  gap: 15px;
  align-items: center;
}

.active.navbarList span {
  color: var(--primary-bg-color);
  font-weight: 500;
}

.profileMenu {
  width: 323px;
  height: 270px;
  padding: 12px 25px;
}

.profileMenu svg {
  /* background: #fff;
  width: 55px;
  height: 55px;
  padding: 9px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029; */
  margin-bottom: 10px;
}

.profileMenu h5 {
  text-align: left;
  font: normal normal normal 19px/28px Roboto;
  letter-spacing: 0px;
  color: #000000;
  margin: 0;
}

.profileMenu p {
  font: normal normal 400 14px/27px Roboto;
  letter-spacing: 0px;
  color: #000000;
  margin: 0 0 27px;
}

.profileMenu div {
  display: flex;
  justify-content: space-between;
}

/* .profileMenu div button:first-child {
  background: var(--primary-bg-color) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: none;
  color: #fff;
  padding: 11px 21px;
}

.profileMenu div button:first-child:hover {
  background: #e5c201 0% 0% no-repeat padding-box;
  cursor: pointer;
  border-color: #e5c201;
} */

.profileMenu div button.help {
  background: #fff 0% 0% no-repeat padding-box;
  border: none;
  color: #000000;
  font-size: 16px;
  padding: 7px 0px;
}

.profileMenu #signout button:last-child {
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: 1px solid #d41317;
  color: #d41317;
  padding: 11px 21px;
}

.profileMenu #signout button:last-child:hover {
  background: #b51013 0% 0% no-repeat padding-box;
  cursor: pointer;
  color: #fff;
  border-color: #b51013;
}

.tableActionBtn {
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 1px solid #1976d2;
  color: #1976d2;
  padding: 6px 11px;
  font-weight: 500;
  font-size: 12px;
}

.tableActionBtn:hover {
  background: #115ba5 0% 0% no-repeat padding-box;
  color: #fff;
  cursor: pointer;
  border-color: #115ba5;
}

.actionDropdown {
  border-radius: 5px !important;
  height: 40px;
  padding: 5px 20px 0;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-right: 5px;
  box-shadow: 0px 3px 6px #00000029;
  padding-left: 20px;
  width: 190px !important;
}

.actionDropdown li {
  display: block !important;
  justify-content: left !important;
  padding: 10px !important;
}

.actionDropdown input {
  opacity: 1 !important;
  border: none !important;
  padding-left: 30px;
  padding-bottom: 10px;
  font-size: medium;
  font-family: "Roboto";
  border-radius: 5px !important;
}

.navbarHedding {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 24px;
  align-items: center;
  font: normal normal 600 18px/29px Roboto;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  max-height: 136px !important;
}

.navbarHedding svg {
  margin: 10px;
}


/* .navbarHedding,
.navbarHeddingclients {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 24px;
  font: normal normal 600 18px/29px Roboto;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  max-height: 136px !important;
} */

.navbarHedding span,
.navbarHeddingclients span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 60px !important;
  align-content: center;
}

.navbarHedding img {
  width: 65px;
  margin: 21px auto 22px;
}

/* .navbarHeddingclients img {
  width: 65px;
  margin: 32px auto 22px;
} */

.navbarHedding>div,
.navbarHeddingclients>div {
  width: 90px;
  height: 90px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 11px;
  box-shadow: 2px 2px 5px #d3d3d3;
}

.navbarHedding>div img {
  margin: 0;
}

.MuiMenuItem-gutters {
  display: block;
  padding: 4px 12px;
  margin: 2px 0;
}

.MuiMenu-list {
  padding: 0;
}

.navbarSection>div {
  top: 0px !important;
  padding-top: 70px !important;
  position: absolute;
  height: 100vh;
}

.text-right {
  text-align: right !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.text-grey {
  color: #454545 !important;
}

.text-center {
  text-align: center !important;
}

.login-page__form .text-center .usericon_div {
  text-align: center !important;
  margin-top: 50px;
}

.activeHyperLink {
  cursor: pointer !important;
  display: inline-block !important;
}

.activeHyperLink:hover {
  cursor: pointer !important;
  font-weight: bold !important;
  text-decoration: underline !important;
}

/* .postingSuitcase {
  width: 76px;
  height: 63px;
} */

.text-default {
  color: #000;
}

.breadCrumbs {
  margin: 0px -33px 20px !important;
  height: 154px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: self-end;
}

.breadCrumbs ol li {
  display: flex;
  align-items: baseline;
}

.breadCrumbs ol li:last-child p {
  font-weight: 600;
  pointer-events: none !important;
}

.breadCrumbs ol li * {
  margin: 0 7px;
  /* cursor: pointer; */
}

.breadCrumbs ol li div {
  width: 42px;
  height: 29px;
}

.breadCrumbs ol {
  max-width: 100%;
  padding-bottom: 25px;
  padding-left: 30px;
  box-sizing: border-box;
}

.breadCrumbpointer {
  cursor: pointer;
}

.contentSection {
  /* padding: 0px 15px; */
  padding: 0px 5px 0 14px;
}

.display-1 {
  font-weight: 100;
  font-size: 67px;
  text-align: center;
}

input.Mui-disabled,
.Mui-disabled,
select:disabled {
  -webkit-text-fill-color: #000 !important;
  cursor: not-allowed !important;
  /* background: #f3f3f3 !important; */
  border-color: #e0e0e0 !important;
}

.labelCustom {
  font-weight: bold !important;
  margin-bottom: 22px;
  display: block;
}

.SelectLabel {
  font-size: 16px !important;
  color: #262626 !important;
  background: #f3f3f3;
  display: inline-block !important;
  padding: 0 4px !important;
  margin: -9px 11px;
  position: absolute !important;
  font-weight: 500 !important;
  z-index: 1;
}

.SelectLabel1 {
  font-size: 15px !important;
  color: #262626 !important;
  background: rgb(255, 255, 255);
  /* display: none !important ; */
  font-weight: bold !important;
  display: inline-block !important;
  padding: 0 4px !important;
  margin: -9px 11px;
  position: absolute !important;
  z-index: 1;
}

.boldlabel label {
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #262626 !important;
}

.boldlabel1 label {
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #262626 !important;
}

.headerLabel {
  color: #262626 !important;
  font-weight: 700 !important;
  position: relative !important;
  margin-bottom: 22px;
  margin-left: -12px;
}

.headerLabel1 {
  color: #262626 !important;
  font-weight: bold !important;
  margin-bottom: 0 !important;
  margin-top: 17px !important;
}

.activebtn {
  color: var(--primary-bg-color) !important;
  font-weight: 450 !important;
  font-family: "Roboto", sans-serif;
}

.nonactivebtn:hover {
  font-weight: 420 !important;
}

.MuiBreadcrumbs-ol li:nth-child(2) {
  display: none;
}

.jobcode_search {
  position: relative;
}

.clientsearch .MuiAutocomplete-endAdornment,
.jobcode_search .MuiAutocomplete-endAdornment,
.clientsearch1 .MuiAutocomplete-endAdornment {
  display: none !important;
}

.clientSearch1,
.jobcode_search {
  border-radius: 50px;
}

/* .clientsearch1:hover {
  width: 100% !important;
  transition: width 2s;
} */

#outlined-basic-helper-text {
  margin-left: 0;
}

.text-danger {
  color: #d32f2f !important;
}

#menu-appbar {
  z-index: 9999 !important;
}

.snackbar_success,
.snackbar_info,
.snackbar_warning,
.snackbar_error {
  z-index: 99999 !important;
}

.MuiAlert-root {
  padding: 10px 32px !important;
  padding-right: 70px !important;
  border-radius: 5px !important;
  position: relative;
  overflow: hidden;
}

.MuiAlert-filledSuccess {
  background: #fff !important;
  color: #2e7d32 !important;
}

.MuiAlert-root:before {
  content: "";
  border: 2px solid;
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
}

.MuiAlert-filledInfo {
  background: #fff !important;
  color: #0288d1 !important;
}

.MuiAlert-filledWarning {
  background: #fff !important;
  color: #ed6c02 !important;
}

.MuiAlert-filledError {
  background: #fff !important;
  color: #d32f2f !important;
}

#Layer_1 {
  width: 550px;
  margin: 0 auto;
}

#Layer_1 path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 4s infinite;
  stroke-width: 3px;
  fill-opacity: 0;
}

#Layer_1 path.cls-1 {
  stroke: #fff !important;
  fill: #fff !important;
}

#Layer_1 path.cls-2 {
  stroke: var(--primary-bg-color) !important;
  fill: var(--primary-bg-color) !important;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
    fill-opacity: 0;
  }

  60% {
    stroke-dashoffset: 0;
    fill-opacity: 0;
  }

  62% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
  }

  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
  }
}

#loader1 {
  background: #010101c6;
  width: 100vw;
  position: fixed;
  z-index: 9999;
  height: 100vh;
}

#loader1 svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MuiPaper-root {
  background: inherit;
}

#jobDescForSMnew .cke_inner div {
  height: 153px !important;
}

#jobDesc .cke_inner div {
  height: 450px !important;
}

.cke_bottom {
  display: none !important;
}

.actionBtnRtl {
  position: absolute;
  right: 2.5%;
  margin-top: -120px;
}

.actionBtnRtl1 {
  position: absolute;
  right: 2.5%;
  margin-top: -90px;
}

.uploadSection {
  display: block !important;
  padding: 18px !important;
  box-shadow: none !important;
  border: none;
  border-radius: 11px !important;
  text-transform: inherit !important;
  font: normal normal normal 18px/24px "Roboto" !important;
  color: #9e9e9e !important;
  width: 79% !important;
  margin: 0 auto 18px !important;
  background-color: var(--secondary-bg-color) !important;
  cursor: pointer !important;
}

.uploadSection span:first-child {
  font: normal normal bold 18px/24px "Roboto" !important;
  color: var(--primary-bg-color);
}

.uploadSection span+span {
  display: none;
}

.cancelBtn {
  background-color: white;
  color: red !important;
  border-radius: 15px;
  width: 6rem;
  font-family: "Roboto" !important;
  font-weight: 600 !important;
}

.cancelBtn:hover {
  font-weight: bolder !important;
}

.saveBtn {
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-background-text-color) !important;
  border-radius: 10px !important;
  width: 6rem !important;
  height: 37px !important;
  width: 96px;
  font-size: 14px !important;
}

.saveBtn:hover {
  font-weight: bolder !important;
  color: var(--primary-bg-color) !important;
  background-color: var(--primary-background-text-color) !important;
}

.saveBtn:disabled {
  background-color: #f5f5f5 !important;
}

.actionBtns {
  text-align: right;
  margin-top: 10%;
}

.actionBtns button:first-child {
  /* margin-right: 22px; */
}

.diabtnjob {
  margin-left: 50px !important;
  margin-top: 20px !important;
}

.diabtn {
  background-color: var(--secondary-bg-color) !important;
  color: var(--secondary-background-text-color);
  text-align: center !important;
}

.btnjobcode {
  color: #000000 !important;
  font: normal normal bold 19px/25px "Roboto" !important;
  opacity: 100%;
  width: 100px;
  height: 20px;
}

.btntext {
  font-weight: 400 !important;
  font: normal normal normal 19px/25px "Roboto";
  letter-spacing: 0px;
  color: #000000;
}

.MuiSnackbar-root {
  right: 120px !important;
  top: 90px !important;
}

#alert-dialog b {
  font-size: 20px;
}

#alert-dialog p {
  margin-left: -10px !important;
  display: flex;
  justify-content: center;

}

#alert-dialog {
  margin-left: 5px !important;
  display: flex;
  justify-content: center;
}

.alert-actionbtns {
  display: flex !important;
  justify-content: space-around !important;
  margin: 0 62px 26px !important;
}

.alert-actionbtns button:first-child {
  color: rgb(0, 0, 0) !important;
  height: 32px !important;
}

.alert-actionbtns button:first-child a {
  color: black !important;
}

.alert-actionbtns button:last-child {
  color: red !important;
  border: 1px solid red;
  /* padding: 6px 13px; */
  border-radius: 10px;
  width: 96px;
  font-size: 14px;
  height: 32px !important;
  margin-left: -50px;
}

.alert-actionbtns button:last-child:hover {
  color: rgb(255, 255, 255) !important;
  background: red;
  border: 1px solid red;
}

.activeRow {
  cursor: pointer !important;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.boldSelectlabel {
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #262626 !important;
  background: #ffff;
}

.boldSelectlabelview {
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #262626 !important;
  background: #f3f3f3;
}

p.Mui-error {
  margin-left: 0 !important;
  font-size: 14px !important;
  font-weight: 400;
}

.brd-danger .cke_chrome {
  border-color: red;
}

p.Mui-error:before {
  content: "\f071";
  font-size: 16px !important;
  position: relative;
  font-family: "FontAwesome";
  margin-right: 10px;
}

.onHover:hover {
  background: var(--primary-background-text-color) !important;
  color: var(--primary-bg-color) !important;
  font-weight: bolder !important;
}

.buttonHover:hover {
  font-weight: bolder !important;
}

.buttonHoverjobBoard:hover {
  font-weight: bolder !important;
  background: var(--primary-background-text-color) !important;
  color: var(--primary-bg-color) !important;

}

.buttonHover1:hover {
  font-weight: bolder !important;
  background: var(--primary-background-text-color) !important;
  color: var(--primary-bg-color) !important;
  border-radius: 10px !important;
}

.viewSection label {
  font-size: 20px;
  color: #262626 !important;
  background: #f3f3f3;
  padding: 0 4px !important;
  z-index: 1;
  font-weight: 700 !important;
}

.filterbtn {
  background-color: var(--primary-bg-color) !important;
  width: 6rem;
  height: 37px;
  border-radius: 10px !important;
  color: var(--primary-background-text-color) !important;
  font-size: 14px !important;
}

.filterbtn:hover {
  font-weight: bolder !important;
  background-color: var(--primary-background-text-color) !important;
  color: var(--primary-bg-color) !important;
}

.col-align-right {
  text-align: right !important;
  position: relative !important;
  padding-left: 46px !important;
  padding-right: 10px !important;
}

.pagingRight .pagination-bar {
  display: inline-flex;
  margin-right: -14px;
}

.pagingRight {
  text-align: right;
}

/* .pl-27 {
  padding-left: 27px !important;
} */

.filterMenuList li {
  display: block !important;
  padding: 5px 13px !important;
  margin-bottom: 1px !important;
}

#InnerComponents .personaldetails {
  display: none;
}

#InnerComponents>div,
#InnerComponents>div>div {
  margin: 0 !important;
  padding: 0 !important;
}

#InnerComponents .addicon {
  display: none;

}

.addcomponent .addicon {
  /* display: none; */
  color: var(--secondary-background-text-color) !important;
  /* background: var(--secondary-bg-color) 0% 0% no-repeat padding-box; */
}

.addicon h4:hover {
  color: var(--primary-bg-color) !important;

}

.educationTermRangePicker .MuiPickersDateRangePickerInput-rangeInputsContainer {
  width: 100% !important;
}

.educationTermRangePicker .MuiTextField-root:first-child .MuiOutlinedInput-notchedOutline {
  border-right-color: #fff !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.educationTermRangePicker .MuiTextField-root:last-child .MuiOutlinedInput-notchedOutline {
  border-left-color: #fff !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.educationTermRangePicker .MuiFormHelperText-contained {
  display: none;
}

.hr-border {
  border-top: 1px solid #e7e7e7 !important;
  border: none;
}

#applicantsView>div+div {
  width: 100% !important;
}

#applicantsView .personaldetails {
  display: none;
}

.MuiCheckbox-indeterminate {
  color: var(--primary-bg-color) !important;
}

.errorHelperText {
  color: #d32f2f !important;
  width: 105% !important;
}

#controlled-demo-helper-text {
  background: none !important;
}

.docsupporttext {
  font-family: "Roboto";
  font-weight: 400 !important;
  font-size: 14px !important;
  margin: 0 0 33px;
}

#clientsView>div>div:first-child {
  display: none !important;
}

.css-10hburv-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
  margin-top: 4px !important;
}

.MuiTableCell-root.MuiTableCell-head {
  background-color: var(--secondary-bg-color) !important;
  color: var(--secondary-background-text-color) !important;
}

.additionalList ul {
  list-style: none;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  padding: 0;
}

.additionalList ul li {
  background: whitesmoke;
  padding: 10px 15px;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
}

.additionalList ul li svg,
.additionalList p svg {
  font-size: 19px;
  cursor: pointer;
  background: #ffffff87;
  border-radius: 5px;
}

.additionalList p {
  background: whitesmoke;
  padding: 10px 15px;
  border-radius: 7px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

div[class*="materialui-daterange-picker-makeStyles-header"],
div[class*="materialui-daterange-picker-MuiDivider-root"],
div[class*="materialui-daterange-picker-MuiGrid-root"] ul {
  display: none;
}

ul[class*="materialui-daterange-picker-MuiMenu-list"] {
  display: block !important;
}

div[class*="materialui-daterange-picker-makeStyles-dateRangePickerContainer"] {
  position: absolute;
}

.cancelbtns {
  margin-right: "25px";
  color: red !important;
}

.appediticon:hover {
  color: var(--primary-bg-color) !important;
}

.appdeleteicon:hover {
  color: #ff0000 !important;
}

.applicantdynatablehead {
  font-family: "Roboto" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  /* text-transform: capitalize; */
}

.dynatableheadtext {
  font-family: "Roboto" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  /* text-transform: capitalize; */
}

.dynatablebodytext {
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  /* text-transform: capitalize; */
  color: #000000 !important;
  opacity: 100% !important;
  font: 14px "Roboto" !important;
}

.Pannelloader {
  position: relative;
}

.Pannelloader .loaderSection {
  position: absolute;
  width: auto;
  left: 50%;
  margin-top: -100px;
}

#inlineEditTable .Mui-disabled {
  background: none !important;
}

#inlineEditTable td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.resultHeader .rightnavsearchbar {
  /* margin: 0; */
  width: 300px;
  margin-left: 280px;
}

.question1textboxes .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline,
.question1textboxes .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

.question1textboxes .Mui-disabled,
.question1textboxes .Mui-disabled .MuiOutlinedInput-input.Mui-disabled {
  background: #fff !important;
  padding: 0 !important;
}

.question1textboxes .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon.Mui-disabled {
  color: #fff;
}

.customizedTable thead tr th .MuiCheckbox-colorPrimary.Mui-checked svg {
  color: var(--primary-bg-color) !important;
}

.customizedTable thead tr th svg {
  color: #fff !important;
}

.align-items-baseline {
  align-items: baseline;
}

.flagname .MuiListItemText-secondary {
  color: var(--headerTextColor) !important;
  /* margin-top: -6px !important; */
  font-size: 16px !important;
  font-weight: 400 !important;
}

.flagname {
  text-transform: capitalize !important;
}

.countrydisplay {
  display: flex !important;
  width: 100%;
  flex-direction: row !important;
}

.innerdiv .MuiSvgIcon-root {
  font-size: 1.2rem;
}

#lock-menu .MuiPaper-elevation {
  top: 12px !important;
}

textarea {
  cursor: auto !important;
}

.pannelContent {
  /* height: calc(100vh - 207px); */
  /* overflow: auto; */
  /* margin-top: 15px; */
  padding: 10px 0;
}

#eqlColHeight>div>div,
#eqlColHeight>div>div>div {
  height: -webkit-fill-available !important;
  height: -moz-available !important;
  height: fill-available !important;
  /* height: 100%; */
  /* height: calc(100% - 28px); */
}

#primarySkills-helper-text {
  position: absolute;
  bottom: -28px;
}

.bg-yellow {
  background: var(--primary-bg-color);
  color: var(--primary-background-text-color) !important;
}

.usericon1 {
  height: 119px;
  width: 105px;
  padding-top: 10px;
}

#auditLog td {
  cursor: default !important;
}

.rm-link {
  color: #3b4046;
  text-decoration: none;
}

.errorHelperText {
  color: #d32f2f !important;
}

#subLayout {
  display: flex;
  width: 100vw;
  height: 99.85vh;
  overflow-y: hidden;
  position: relative;
  margin: 0 auto;
}

#mainLayout {
  display: flex;
  max-height: calc(100vh - 1px);
  overflow: auto;
}

#uploadviewer .sidebar {
  width: 640px !important;
}

.floatBtn {
  width: 50px;
  position: absolute;
  bottom: 45px;
  right: 22px;
  cursor: pointer;
}

.floatBtn svg {
  box-shadow: 0px 4px 7px #a5a5a5;
  border-radius: 50%;
}

.floatBtn:hover path {
  fill: var(--primary-bg-color) !important;
}

.abcdef .ndfHFb-c4YZDc-Wrql6b {
  display: none !important;
  opacity: 0 !important;
}

.btnHover {
  cursor: pointer !important;
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-background-text-color) !important;
}

.btnHover:hover {
  background-color: var(--secondary-bg-color) !important;
  color: var(--primary-bg-color) !important;
  cursor: pointer !important;
}

ul.listbtns li span {
  margin-left: 13px;
}

#customFields .boldlabel label,
#customFields .boldSelectlabel {
  background: #fff !important;
  padding: 0 4px 0 0 !important;
  font-weight: 700 !important;
}

.viewSection #customFields .boldlabel label,
.viewSection #customFields .boldSelectlabel {
  background: #f3f3f3 !important;
  padding: 0 4px 0 0 !important;
}

.viewSectionboldSelectlabel {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #262626 !important;
  background: #f3f3f3 !important;
  padding: 0 4px 0 0 !important;
}

iframe#iframe .ndfHFb-c4YZDc div:last-child {
  border: 1px solid red !important;
}

.selectOptions {
  display: block !important;
  padding: 7px 10px !important;
}

#stepsContent #jpn_breadcrumb,
#stepsContent .jpacionbuttons {
  display: none !important;
}

.MuiSwitch-thumb {
  width: 12px !important;
  height: 12px !important;
  background: #000 !important;
  margin-top: 4px !important;
  margin-left: 4px !important;
}

.MuiSwitch-track,
.Mui-checked .MuiSwitch-track {
  background-color: #fff !important;
}

.Mui-checked .MuiSwitch-thumb {
  background-color: var(--primary-bg-color) !important;
}

.td-flex {
  /* display: flex !important; */
  justify-content: center;
  align-items: center;
}

.td-flex img {
  margin-left: 8px;
  cursor: pointer;
}

.rangeText {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* gap: 10px; */
}

.rangeText .suffixtext span {
  position: absolute !important;
  right: 10px;
  font-size: 14px;
  line-height: 3.1;
  display: block;
}

.rangeText .suffixtext {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  justify-content: space-between;

}

.rangeText .suffixtext input {
  background: #fff;
  border: none;
  box-shadow: 0px 3px 8px #bebebe;
  border-radius: 6px;
  padding: 8px 51px 8px 8px;
  height: 26px;
  width: 58px;
  /* margin-right: 4px; */
}

.innerDropdown {
  width: 120px !important;
  margin-right: 7px;
}

.innerDropdown .MuiOutlinedInput-notchedOutline {
  border: none !important
}

.innerDropdown .outlined-basic {
  padding: 0 !important;
}

.innerDropdown svg {
  right: 3px !important;
}

.text-secondary {
  font-size: 14px !important;
}

.selectOptions {
  display: block !important;
  padding: 5px 15px !important;
}

.customModal {
  background: #00000082;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
}

.customModalDiv {
  width: 350px;
  background: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 101px;
  /* padding: 0px 24px; */
  border-radius: 10px;
  box-shadow: 0px 0px 3px #00000063;
}

.customModal h1 {
  background: var(--secondary-bg-color);
  color: #fff;
  font-size: 20px;
  font-weight: normal;
  padding: 14px;
  margin: 0;
  border-radius: 10px 10px 0 0;
}

.customModal .modal-body {
  padding: 0 15px 20px;
  font-size: 14px;
}

.customModal .modalActionBtns {
  margin-top: 30px;
}

.customModal .modalActionBtns button {
  width: 145px !important;
}


/*duplicant applicants popup start*/

.duplicatecustomModal {
  background: #00000082;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
}

.duplicatecustomModalDiv {
  width: 904px;
  background: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 101px;
  /* padding: 0px 24px; */
  border-radius: 10px;
  box-shadow: 0px 0px 3px #00000063;
}

.duplicatecustomModal h1 {
  background: var(--secondary-bg-color);
  color: var(--secondary-background-text-color);
  font-size: 19px;
  font-weight: bold;
  padding: 14px 40px;
  margin: 0;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
}

.duplicatecustomModal .duplicatemodal-body {
  padding: 0 15px 20px;
  font-size: 14px;

}

.duplicatecustomModal .duplicatemodal-body h4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  font-size: 19px;
}

.duplicatecustomModal .modalActionBtns {
  margin-top: 30px;
  justify-content: flex-end;
  display: flex;
}

.duplicatecustomModal .modalActionBtns button {
  width: 167px !important;
}

.duplicatecustomModal .duplicateapplicanttabs {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.duplicatecustomModal .duplicateapplicanttabs>div {
  background: #D9D9D9;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  width: 224px;
  cursor: pointer !important;
  text-align: left;
  height: 165px;
  padding-bottom: 11px;
}

.duplicatecustomModal .duplicateapplicanttabs>div>p {
  font-size: 22px;
  text-align: center;
  margin-top: 54px;
}

/* .duplicatecustomModal .duplicateapplicanttabs>div >p:hover{
  font-size: 17px;
  text-align: center;
  margin-top: 54px;
  color: var(--primary-bg-color) !important;
  
} */
.duplicatecustomModal .duplicateapplicanttabs>div:hover {
  color: var(--primary-bg-color) !important;
  background: var(--secondary-bg-color) !important;
}

/*duplicant applicants popup end*/


/*twoduplicant applicants popup start*/

.twoduplicatecustomModal {
  background: #00000082;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
}

.twoduplicatecustomModalDiv {
  width: 550px;
  background: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 101px;
  /* padding: 0px 24px; */
  border-radius: 10px;
  box-shadow: 0px 0px 3px #00000063;
}

.twoduplicatecustomModal h1 {
  background: var(--secondary-bg-color);
  color: var(--secondary-background-text-color);
  font-size: 19px;
  font-weight: bold;
  padding: 14px 40px;
  margin: 0;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
}

.twoduplicatecustomModal .twoduplicatemodal-body {
  padding: 0 15px 20px;
  font-size: 14px;
  text-align: center !important;

}

.twoduplicatecustomModal .twoduplicatemodal-body h4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 40px 0px 30px;
  font-size: 16px;
  font-weight: 400;
}

.twoduplicatecustomModal .modalActionBtns {

  display: inline-block;
  background: #f5f5f5;
}

.twoduplicatecustomModal .modalActionBtns .viewBtn {

  background-color: var(--primary-bg-color) !important;
  color: var(--primary-background-text-color) !important;
  border-radius: 15px !important;
  width: 7rem !important;
  height: 37px !important;
}

.twoduplicatecustomModal .modalActionBtns .viewBtn:hover {
  font-weight: bolder !important;
  color: var(--primary-bg-color) !important;
  background-color: var(--primary-background-text-color) !important;
}

/*twoduplicant applicants popup end*/

.checkboxapplicants.Mui-checked {
  color: var(--primary-bg-color) !important;
}

.MuiTableCell-body {
  vertical-align: initial !important;
}

.br-box {
  border-right: 1px solid #bcbcbc !important;
}

.customPicker {
  width: 100% !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.isCurrentSection .MuiInputBase-root,
.isCurrentSection .customPicker {
  width: 165px !important;
}

.customPicker1 .MuiOutlinedInput-notchedOutline {
  border: none;
}

.customPicker1 .MuiOutlinedInput-root {
  display: flex;
  flex-direction: row-reverse;
}

.customPicker1 input {
  padding-top: 0;
  padding-bottom: 0;
  width: 100px;
}

#html .cke_contents {
  height: 300px !important;
}

.action-btn-dropdown {
  position: absolute;
  right: 33px;
  margin-top: -93px;
}

#mailtemplatebody {
  text-align: left !important;
}

.profileMenu .settingstext {
  margin: 0px;
  text-align: left;
  letter-spacing: 0px;
  font-size: 16px;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  color: #000000 !important;
  cursor: pointer;
}

.ExtraSmall Button {
  height: 32px !important;
}

.MuiButton-sizeSmall {
  height: 40px !important;
}

.MuiButton-sizeMedium {
  height: 48px !important;
}

.MuiButton-sizeLarge {
  height: 56px !important;
}

.sidebar .addApplicantcancelBtn a {
  /* background-color: white; */
  color: red !important;
  border-radius: 10px !important;
  width: 6rem;
  font-family: "Roboto" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.sidebar .addApplicantcancelBtn:hover {
  font-weight: bolder !important;
}

.signout {
  border-radius: 10px !important;
  font-size: 14px !important;
  height: 32px !important;
}


.activeBtn .MuiSwitch-thumb {
  margin: 4px !important;
}

/*active*/
.Mui-checked .MuiSwitch-thumb {
  background-color: var(--primary-bg-color) !important;
}

.Mui-checked+span.MuiSwitch-track {
  background: #5d5d5d !important;
  opacity: 1 !important;
}

/*inactive*/
.MuiSwitch-thumb {
  background: #ffffff !important;
  margin: 4px !important;
}

.MuiSwitch-track {
  background: #bcb9b9 !important;
  opacity: 1 !important;
}

.activeBtn .Mui-checked .MuiSwitch-thumb {
  margin-top: 6px !important;
  right: 2px !important;
  position: relative;
}

.activeBtn .Mui-checked+span.MuiSwitch-track {
  height: 18px;
  border-radius: 10px;
}

.inActiveBtn .MuiSwitch-thumb {
  margin-top: 6px !important;
  left: 10% !important;
  position: relative;
}

.inActiveBtn .MuiSwitch-track {
  height: 18px;
  opacity: 1 !important;
  border-radius: 10px;
}

.allcountrydisplay{
  padding: 9px 10px 0 4px;
}

#headerToggle .headerToggleBtn .MuiSwitch-thumb {
  background: var(--secondary-bg-color) !important;
  height: 12px !important;
  width: 12px !important;
  margin-top: 5px !important;
  margin-left: 6px !important;
}
#headerToggle .headerToggleBtn .Mui-checked .MuiSwitch-thumb {
  margin-left: 5px !important;
}
#headerToggle .headerToggleBtn .MuiSwitch-track {
     background-color: var(--primary-bg-color) !important;
     opacity: 1 !important;
     height: 17px !important;
     border-radius: 10px;
 }

 #headerToggle .MuiSwitch-root {
  width:60px;
}

.jobcode_search .MuiAutocomplete-hasPopupIcon .MuiFormControl-root{
  width: 200px!important;
}

@media (min-width: 1389px) {
  .drop_down .groupButtons .MuiButtonGroup-contained {
    max-width: 45%;
    margin-right: 10px;
    margin-top: 10px;
  }
}

@media (min-width: 1156px) and (max-width: 1272px) {
  .drop_down {
    /* margin-bottom: -7px; */
    display: inline-block;
    background-color: green !important;
  }

  .drop_down .groupButtons .MuiButtonGroup-contained {
    width: 50% !important;
    margin-top: 13px;
  }

  .drop_down+.actionSectionAssoc .searchdivcss>div {
    width: 222px !important;
    min-width: auto;
  }

  .drop_down+.actionSectionAssoc .actionDropdowncss>div>div {
    width: 150px !important;
  }

}

@media screen and (min-width: 1010px) and (max-width: 1156px) {
  .drop_down {
    margin-bottom: 50px !important;
    display: inline-block;
  }

  .drop_down .groupButtons .MuiButtonGroup-contained {
    width: 70% !important;
  }

  .drop_down+.actionSectionAssoc .searchdivcss>div {
    min-width: auto;
  }

  .drop_down+.actionSectionAssoc .actionDropdowncss>div>div {
    width: 250px !important;
  }
}

@media screen and (min-width: 556px) and (max-width: 1017px) {
  .drop_down {
    margin-bottom: 50px !important;
    display: inline-block;
  }

  .drop_down .groupButtons .MuiButtonGroup-contained {
    width: 90% !important;
    margin-left: -25px;
  }

  .drop_down+.actionSectionAssoc .searchdivcss>div {
    min-width: auto;
  }

  .drop_down+.actionSectionAssoc .actionDropdowncss>div>div {
    width: 250px !important;
  }
}

@media screen and (min-width: 636px) and (max-width: 1156px) {
  .breadCrumbs ol {
    max-width: 75%;
  }
}