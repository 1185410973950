/* eslint-disable react/prop-types */
.searchbar {
  padding-top: 30px;
  border-radius: 300px;
}

.addicon {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.textfielddirec {
  display: flex;
  flex-direction: column;
  gap: 32.5px;
  width: 429px;
}

.textfieldsize .MuiInputBase-input {
  width: 150px;
  height: 40px;
}

.dynatablebodytextemail {
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  color: #000000 !important;
  opacity: 100% !important;
  font: 14px "Roboto" !important;
}

.addapplicantsection {
  cursor: pointer !important;
  position: absolute;
  margin-top: 54px;
  margin-bottom: 15px;
  margin-left: 1395px;
  height: 37px;
  gap: 10px;
  /* width: 91.4%; */
  text-align: right;
  display: inline-flex;
  justify-content: end;
}

.addnewapplitext {
  margin-top: 5px;
  font: normal normal normal 20px/26px "Roboto";
  letter-spacing: 0px;
  color: #5D5D5D;
  opacity: 1;
}

.addnewapplitext:hover {
  color: var(--primary-bg-color) !important;
}

.Applicantuploadfileicon {
  margin-top: 55px;
  padding-bottom: 40px
}


.appuploadresume {
  width: 340px;
  display: inline-block !important;
  padding: 18px 30px !important;
  box-shadow: none !important;
  border: 2px dashed var(--primary-bg-color) !important;
  border-radius: 10px !important;
  text-transform: inherit !important;
  font: normal normal normal 18px/24px "Roboto" !important;
  margin: 0 auto 20px !important;
  background-color: white !important;
  cursor: pointer !important;
  margin-top: - 30px !important;
  height: 64px !important;
}

.appuploadresume:hover {
  background-color: #000000 !important;
  display: none;
  border: 2px solid #3B4046 !important;
}

.appuploadresume:hover span b:last-child {
  display: none;
}

.appuploadresume span:first-child {
  font-family: normal normal bold 18px "Roboto" !important;
  color: var(--primary-bg-color);
}

.appuploadresume span+span {
  display: none;
}

.applidocsupporttext {
  margin-top: -10px;
}

.filelistupload {
  list-style: none;
  text-align: right;
  font-size: 15px;
  height: 200px;
  overflow-y: auto;
  padding: 0;
  border: 1px solid #efefef;
}

.filelistupload li {
  line-height: 2.4;
  display: flex;
  align-items: baseline;
  margin: 2px 0;
  background-color: #fff;
  padding: 0 25px;
}

#bulkUploadResults .sidebar .pannelContent {
  height: calc(100vh-106px);
}

.filelistupload li span svg {
  color: red;
  font-size: 17px;
  vertical-align: middle;
  padding: 8px;
  cursor: pointer;
}

.filelistupload li p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 85%;
  text-align: left;
}

.sourcedselectmenulist {
  display: flex !important;
  flex-direction: column !important;
  align-items: start !important;
  padding: 7px 7px 0 8px !important;
}

.sourcedfrominput .MuiOutlinedInput-input {
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  padding-left: 15px;
}

/* advance search button */
.advanceSearchbtn {
  position: relative;
  height: 30px;
}

.advanceSearchbtn1 {
  position: absolute!important;
  margin-top: 27px!important;
}

.advanceSearchbtn button img {
  vertical-align: bottom;
  margin-right: 12px;
}

.advanceSearchbtn button {
  width: 189px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 66px;
  border: none;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #3B4046;
  padding: 10px 0px;
  position: absolute;
  z-index: 2;
}

.d-flex {
  display: flex;
}

/* .adSearchPannel {
  min-height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  margin-top: -63px;
  padding: 100px 30px 0;
} */

.adSearchPannel {
  height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    margin-top: -63px;
    padding: 100px 30px 0;
    position: fixed;
    width: 15.3%;
}

.adSearchPannelList {
  list-style: none;
  padding: 0;
}

.adSearchPannelList li div.active {
  font-weight: bold;
}

.adSearchPannelList li div:first-child {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.adSearchPannelList li div .MuiBox-root {
  justify-content: flex-start;
}

.adSearchPannelList li div:first-child {
  display: flex;
}

.adSearchPannelList li {
  line-height: 1.6;
  margin-bottom: 15px;
}

ul.listbtns {
  padding: 0;
  list-style: none;
  margin-top: 7px;
}

ul.listbtns li {
  margin: 0;
  border: 1px solid #bebebe;
  display: inline;
  padding: 6px 17px;
  font-size: 14px;
  margin-right: 6px;
  border-radius: 32px;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 6px;
}

ul.listbtns li.active {
  background: var(--primary-bg-color);
  color: var(--primary-background-text-color) !important;
  border: none;
}

.adSearchBox {
  border: none;
  background: #F2F2F2;
  border-radius: 25px;
  height: 30px;
  padding: 0px 0px 0px 15px;
  width: 100%;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 8px;
}

.adSearchBox:focus-visible {
  outline: none !important;
}

.actionBtns .cancelBtn.Mui-disabled{
  background: transparent!important;
}

.NameCapitalise {
  text-transform: capitalize;
}