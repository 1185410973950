/* .registerBackground{
  background-image: url("../../../../public/assests/backgroundimg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width:75%;
  height:100vh;
  margin:0px;
  padding:0px;
  position: fixed;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding:30px;
}
.registerBackground p{
  color:black;
} */

.registerBackground-otp {
  background-repeat: no-repeat;
  width: 75%;
  height: 100vh;
  margin: 0px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 30px; */
  background-color: #E4E4E4 !important;
}

.registerBackground-otp .BGImg2otp {
  left: 20%;
  position: relative;
  /* top: 25%; */
}

.BGImg1otp {
  display: flex;
  /* position: absolute; */
  top: 0%;
  align-items: flex-start;
  justify-content: inherit;
  width: 85%;
  position: absolute;
}

.BGImg2otp img {
  width: 100%;
}

.registration-container-otp {
  transform: translateX(-50%);
  background-color: #FFFFFF;
  border-radius: 44px;
  box-shadow: 0px 3px 6px #00000029;
  max-height: 646px;
  min-width: 350px;
  max-width: 450px;
  min-height: 580px;
  position: relative;
  left: 95.2%;

}

.topLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.topLinks a {
  margin: 40px;
  text-decoration: none;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.m-2 {
  margin: 20px;
}

.text-center {
  text-align: center;
}

.tab {
  margin: 20px;
  text-decoration: none;
  color: black;
  width: 80px;
  cursor: pointer;
  border-bottom: 3px solid white;
}

.tab.active {
  color: var(--primary-bg-color);
  border-bottom: 3px solid var(--primary-bg-color);
}

.justify-content-start {
  justify-content: flex-start;
}

.flex-column {
  flex-direction: column;
}

.text-left {
  text-align: left;
}

.eyeicon {
  position: absolute;
  top: -35px;
  left: 200px;
  width: 30px;
}

.ml-2 {
  margin-left: 70px;
  margin-right: 70px;
}

.btn-submitotp-verify {
  width: 190px;
  height: 48px;
  font-family: "Roboto";
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-background-text-color) !important;
  box-shadow: 0 3px 6px #00000029;
  border: 0;
  border-radius: 10px !important;
  cursor: pointer;
  font-size: 16px !important;
  margin-left: 72.5px !important;
  margin-top: 122.5px !important;
  font-weight: 500;
}

.btn-submitotp-verify :hover {

  background-color: var(--secondary-bg-color) !important;
  color: var(--primary-bg-color) !important;

}

.backtologin {
  display: block;
  margin-top: 15px !important;
  font-size: 18px;
  color: #3B4046;
  margin-left: 72.5px !important;
}

.justify-content-center {
  justify-content: center;
}

.forgotlink2 {
  text-decoration: none;
  color: black;
  background-color: white;
  border: none;
  cursor: pointer !important;
  font-weight: bold;
  font-size: 16px;
}

.h600 {
  min-height: 612px !important;
  min-width: 438.86px !important;
}

.didntreceive p {
  margin-left: 87px;
  margin-top: 43px;
}

.hirewinglogoverifyotp {
  width: 150px;
  height: 58px;
  margin-top: 35%;
}

/* //////////////////////////////////////////////////////////////////// */

@media only screen and (max-width: 1500px) {
  .BGImg2otp img {
    width: 80%;
  }
}

@media only screen and (max-width: 1320px) {
  .BGImg2otp img {
    width: 60%;
  }
}

@media only screen and (max-width: 1150px) {
  .BGImg2otp img {
    width: 45%;
  }
}

@media only screen and (max-width: 1000px) {
  .BGImg2otp img {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .registerBackground-otp .BGImg1otp {
    display: none;
  }

  .registerBackground-otp {
    background-color: var(--primary-bg-color) !important;
    color: var(--primary-background-text-color) !important;
  }
}

@media only screen and (max-width: 600px) {
  .registration-container-otp {
    margin: auto !important;
    width: 100%;
  }
}


@media only screen and (min-width: 1800px) {
  .btn-submitotp-verify {
    margin-left: 75.5px !important;
  }

  .backtologin {
    margin-left: 75.5px !important;
  }
}