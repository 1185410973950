.personiconassociate {
    display: flex;

}

.breadcrumbsjobassociate {
    padding-bottom: -90px;
    margin-bottom: -18px;
    padding-left: 15px;
}

.formdropdown {
    right: 60px;
    width: 150px;
}

.containercontent {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
}

.tableCompAssociate {
    margin-top: -58px;
    width: 1380px;
    margin-left: 50px;
}

/* .jobpostingasaphead{
    font-family: "Poppins"!important;
    font-weight: 500!important;
    text-transform:capitalize;
    font: normal normal medium 18px/27px "Poppins" !important;
}
.jobpostingasapbodycss{
    font-family: "Poppins"!important;
    font-weight: 400!important;
    text-transform:capitalize;
    color: #000000 !important;
    opacity: 1 !important;
    font: normal normal normal 14px "Poppins"!important;
     
} */
.addassociatetext {
    margin-left: 1200px;

}

.addnewdocumenttext {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-27) var(--unnamed-font-family-Roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-3b4046);
    text-align: left;
    font: normal normal normal 18px/27px "Roboto";
    letter-spacing: 0px;
    color: #3B4046;
    opacity: 1;
}

.tabs .loaderSection{
  position:absolute;
  width:100%;
  height:45%;
}

.tabs .MuiTabs-flexContainer {
    justify-content: center !important;
    gap: 35px !important;
}


.tabs .MuiTab-root {
    color: #3B4046 !important;
    font-size: 21px !important;
    font-weight: 400;
}

.tabs .Mui-selected {
    color: #3B4046 !important;
    /* background-color: red; */
    font-weight: bolder !important;
}

.tabs .MuiTabs-indicator {
    background-color: var(--secondary-bg-color) !important;
}

.appuploadresume {
    min-width: 350px !important;
    display: inline-block !important;
    padding: 18px 30px !important;
    box-shadow: none !important;
    border: 2px dashed var(--primary-bg-color) !important;
    border-radius: 10px !important;
    text-transform: inherit !important;
    font-family: normal normal normal 18px/24px "Roboto" !important;
    margin: 0 auto 20px !important;
    background-color: white !important;
    cursor: pointer !important;
    margin-top: - 30px !important;
    height: 64px !important;
}

.appuploadresume:hover {
    background-color: #000000 !important;
    display: none;
    border: 2px solid #3B4046 !important;
}

.appuploadresume:hover span b:last-child {
    display: none;
}

.appuploadresume span:first-child {
    font-family: normal normal bold 18px "Roboto" !important;
    color: var(--primary-bg-color);
}

.appuploadresume span+span {
    display: none;
}

.mt-5 {
    margin-top: 40px;
}

.sidebar .MuiTabPanel-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.sidebar a{
    color: #4b5055!important
}

.tabsvalue{
    text-transform: capitalize !important;
}
.UpdateStatustabsvalue{
    text-transform: capitalize !important;
    width: 100%;
}

.bulkstatusselectfeild{
  align-content: center !important;
}

.questionsarea{
    border:1px solid #E5E5E5;
    border-radius: 5px;
    min-height: 52px;
    margin-top: 35px;
  }
  
  .questionsarea h3 {
    margin: -12px 0 0 10px;
    font-size: 19px !important;
    text-align: left;
    background: #ffff;
    display: table;
    padding: 0px 6px;
    color: #262626;
  }
  .questionsarea p {
    margin: 10px 0 10px 11px;
    text-align: left;
    padding: 0px 6px;
    color: #A7A7A7;
    word-wrap: break-word;
  }

  .togglebtn {
    margin-left: 79%;
    position: absolute;
    margin-top: 34px;
  }

  .portalActive {
    color: var(--primary-bg-color);
  }

  .groupButtons{
    width: 100%;
    text-align: center;
    position: absolute;
    margin-top: 13px;
  }
  
  .groupButtons button{
    background: #ffffff;
    color: #000!important;
    border: none!important;
    padding: 6px 12px;
  }
  
  .groupButtons button.active, .groupButtons button:hover{
    background: var(--primary-bg-color);
    color: var(--primary-background-text-color)!important;
    font-weight: 600;
  }
  
  .groupButtons .MuiButtonGroup-contained{
    border-radius: 30px!important;
    overflow: hidden;
  }
  
  #bulkUploadResults .sidebar .pannelContent {
    height: calc(100vh-106px);
  }

  #bulkUploadResults article {
    text-align: center;
    margin-top: 0px;
  }

  .usericon {
    /* padding: 30px 0px; */
    margin: 30px 0px;
    margin-left: 20px;
    width: 51px;
    height: 51px;
}

.sidebar .pannelContent article .Applicantprofilearticle{
  text-align: left;
}
/* .sidebar .pannelContent{
height: calc(100vh - 110px);
} */

.Applicantprofilearticle .jobnote {
  margin-left: 120px;
  margin-top: -120px;
  padding: 32px 0px;
  width: 88% !important;
  word-break: break-all;
}

.createdDate {
  color: #CBCBCB;
}
.secline {
  width: 100%;
}

.jobsdatacss {
  row-gap: 10px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-top: 15px;
}

.jobsdatacss td {
  width: 20px ;
  text-align: center;
}

.jobsdatacss tr td:first-child{
  min-width: 155px;
  text-align: left;
  color: #262626;
  font-weight: 500;
}
.jobsdatacss tr td:last-child{
  width: 600px;
  text-align: left;
  font-weight: 400;
  color:  #262626;
}

.jobsdataView {
  row-gap: 10px;
  display: flex;
  margin-bottom: 15px;
  font-size: 16px;
  margin-top: 15px;
}

.jobsdataView td {
  width: 20px ;
  text-align: center;
}

.jobsdataView tr td:first-child{
  min-width: 155px;
  text-align: left;
  color: #262626;
  font-weight: 500;
  padding:5px 5px;
}
.jobsdataView tr td:last-child{
  width: 600px;
  text-align: left;
  font-weight: 400;
  color:  #262626;
}

.updatestatuscss{
  min-width: 40px ;
  text-align: center;
}

.statusdropdwnView{
  margin-top: 2%;
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin-bottom: 2%;
}
.statusdropdwnView p {
  margin-right: 8%;
  color: #262626;
  font-weight: 500;
}

.statusdropdwnView .MuiOutlinedInput-input {
  text-align: left;
  padding-left: 15px !important;
  padding-top: 15px !important;
}

.savebtn {
  background-color: var(--primary-bg-color) !important;
  width: 6rem;
  height: 37px; 
  border-radius: 10px !important;
  color: var(--primary-background-text-color) !important;
}

.savebtn:hover{
  font-weight: bolder !important;
  color:  var(--primary-bg-color) !important;
  background-color: var(--primary-background-text-color) !important;
}

.savebtn:hover{
  font-weight:900
}
.savebtn:disabled {
  background-color: #dadada !important;
}
.updatebuttonView {
  padding: 10px 0;
  float: right;
  font-size: 16px !important;
  width: 150px !important;
  border-radius: 10px;
  padding-left: 275px;
}
.updatebutton1 {
  padding: 30px 0;
  float: right;
  font-size: 16px !important;
  width: 150px !important;
  border-radius: 10px;
}

.typeCss {
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
}
.typeCss span{
  float: left;
  margin-right: 94px;
  color: #262626;
  font-weight: 500;
  align-content: center;
}
.typeCss .MuiOutlinedInput-notchedOutline {
  display: none;
}

.noteTypeselect {
  width: 134px; 
  height: 32px;
  border: 0.30px solid #D1D1D1;
}
.noteTypeselect .MuiOutlinedInput-input {
  text-align: left;
  padding-left: 15px !important;
  padding-top: 15px !important;
}


#bulkUploadStatus article {
  margin: 0px ;
}

#bulkUploadStatus .tabs .MuiBox-root {
  display: flex;
  justify-content: start;
  align-items: center;
}
#bulkUploadStatus .tabs span.MuiTabs-indicator{
  left: 0px !important;
  width: 101.488px !important;
}

.bulkupdateStatusText {
  line-height: 2;
}
.bulkupdateStatusText td {
  font-size: 18px;
  padding: 0px 10px 0px 0px;
}

.bulkUploadStatusUpdateButton {
    padding: 10px 0;
    float: right;
    font-size: 16px !important;
    width: 150px !important;
    border-radius: 10px;
    margin: 120px -10px 0px 0px;
}

.updateButton {
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-background-text-color) !important;
  border-radius: 10px !important;
  width: 6rem !important;
  height: 40px !important;
  width: 96px;
  font-size: 14px !important;
}
.updateButton:hover {
  color: var(--primary-bg-color) !important;
  background-color: var(--primary-background-text-color) !important;
  border-radius: 10px !important;
  width: 6rem !important;
  height: 40px !important;
  width: 96px;
  font-size: 14px !important;
}


.pdfView{
  height: 129px;
  width: 132px;
  background-color: #FFFFFF;
  margin-right: 30px;
  margin-bottom: 10px;
  margin-left: 28px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pdficonView{
  height: 75px;
  width:75px;
  padding-top: 7px;
  margin-bottom: 3px !important;
}
.iconsView{
  display: flex;
}


.documentdownloadiconView {
  position: relative;
}
.documentdownloadiconView:hover:after {
  color: var(--primary-bg-color);
  fill: var(--primary-bg-color);
  content: "download";
  position: absolute;
  font-size: 9px;
  top: 35px !important;
  margin-top: -5px;
  text-transform: capitalize !important;
}
.documentdownloadiconView:hover{
  background-color: transparent !important;
}
.documentdownloadiconView svg {
  height: 23px;
  width: 19px;
  fill: #3B4046;
  margin-top: -12px;
  padding: 0px;
}
.documentdownloadiconView:hover:after,
.documentdownloadiconView:hover svg path {
  content: "download";
  color: var(--primary-bg-color) ;
  fill: var(--primary-bg-color) ;
}


.iconView {
  position: relative;
  padding: 0px ;
}

.iconView:hover:after {
  color: var(--primary-bg-color);
  fill: var(--primary-bg-color);
  content: "view";
  position: absolute;
  font-size: 9px;
  top: 35px !important;
  margin-top: -5px;
  text-transform: capitalize !important;
}

.iconView:hover {
  background-color: transparent !important;
}

.iconView svg {
  height: 23px;
  width: 19px;
  fill: #3B4046;
  margin-top: -12px;
}
.iconView svg:hover path{
  fill: var(--primary-bg-color);
}
.iconView:hover:after,
.iconView:hover svg path {
  content: "view";
  color: var(--primary-bg-color) ;
  fill: var(--primary-bg-color) ;

}
.iconView:hover img{
  height: 23px;
  width: 28px;
  fill: var(--primary-bg-color);
  color:  var(--primary-bg-color);
}