/* eslint-disable react/prop-types */
#clientsbuttons {
    display: flex;
    /* gap: 20px; */
    margin-top: 250px;
    justify-content: end;
}

#clientsbuttons .clientcancelbtn {
    width: 6rem;
    color: #FF0000 !important;
    opacity: 1;
    /* margin-top: 10px !important; */
    font-size: 16px !important;
    height: 25px;
    font-family: "Roboto" !important;
    font-weight: 500 !important;
    letter-spacing: 0px !important;

}

.clientcancelbtn:hover {
    font-weight: bolder !important;
}

button {
    text-transform:initial !important;
}

.clientssubmitbtn:hover {
    color: var(--primary-bg-color) !important;
    background-color:var(--primary-background-text-color) !important ;
    margin-top: 5px !important;
    width: 96px;
    height: 34px;
    font-family: "Roboto";
    font-weight: 500 !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    /* font-size: 16px; */
}

.clientssubmitbtn {
    margin-top: 5px !important;
    width: 96px;
    height: 34px;
    background-color: var(--primary-bg-color) !important;
    color: var(--primary-background-text-color) !important;
    font-family: "Roboto";
    font-weight: 500 !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    font-size: 16px;
}