.add-dialouge {
  margin-top: 0% !important;
}

.add-dialouge .dialog-content {
  min-height: 450px;
}

.add-dialouge .form-container {
  display: flex;
  justify-content: space-between;
  margin: 30px 30px 0px;
  flex-wrap: wrap;
}

.add-dialouge .form-container .form-div {
  width: 47%;
  margin-bottom:25px;
}

.add-dialouge
  .MuiDialog-container
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  max-width: 717px;
  width: 717px;
}

.add-dialouge .message-container {
  display: flex;
  justify-content: space-between;
  margin: 0px 30px;
  flex-wrap: wrap;
}

.add-dialouge .dialog-button {
  background-color: var(--secondary-bg-color)!important;
  color: var(--secondary-background-text-color);
}

.add-dialouge .select-widget {
  width: 100%;
  height: 3.5rem;
  border-radius: 4px;
}

.add-dialouge .message-text {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 15px 10px;
}

.add-dialouge .sample-img-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-dialouge .sample-img {
  margin-top: 20px;
  height: 257px;
  width: 100%;
}

.add-dialouge .status-container {
  display: flex;
  width: 100%;
}

.add-dialouge .actions-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeicon-modelpopup {
  width: 15px;
  height: 20px;
  cursor: pointer !important;
  position: absolute;
  right: 20px;
  top: 22px;
}

.dashBoardDropdownStatusvalues.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper{
  max-height: 280px;
  overflow-y: auto;
}