.dashboard{
    margin-top: 100px;
    box-sizing: border-box;
    padding: 20px;
    min-height: 600px;
}

.dashboard__toolbar {
    text-align: right;
    padding-right: 20px;
    display: flex;
}

.dashboard__toolbar-button {
    background-color: var(--secondary-bg-color)!important;
    color: var(--secondary-background-text-color) !important;
    padding: 7px 30px !important;
    border-radius: 10px !important;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 16px !important;
}

.dashboard__toolbar-button--small {
    background-color: var(--secondary-bg-color) !important;
    color: var(--secondary-background-text-color);
    padding: 7px 30px !important;
    border-radius: 10px !important;
}

.dashboard__grid-item {
    position: relative;
    box-sizing: border-box;
    height: auto; /* Allow height to be based on content */
}

.dashboard__card {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 19px !important;
    background: #FFFFFF;
}

.dashboard__card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--primary-bg-color) 0% 0% no-repeat padding-box;
    font: normal normal bold 18px / 24px Roboto;
    border-top-left-radius: 19px;
    color: var(--primary-background-text-color) !important;
    position: relative;
    height: 50px;
    width: 100%;
    padding-left: 20px;
    font-weight: bold !important;
}

.dashboard__card-header:after {
    content: "";
    width: 30px;
    height: 50px;
    background: var(--primary-bg-color);
    color: var(--primary-background-text-color) !important;
    position: absolute;
    right: -14px;
    transform: translate(0px, 0px) skew(-27deg, 0deg);
    margin-bottom: 1px;
}

.cardTitleGlobe {
    position: absolute;
    right: 18px;
}

.dashboard__card-title {
    font-weight: bold;
}

.dashboard__card-icons {
    padding: 10px 20px !important;
}

.dashboard__card-icon {
    padding-right: 20px !important;
   cursor: pointer;
}
.dashboard__card-icon svg{
    width: 1em;
    height: 1em;
}
.dashboard__card-content {
    display: flex;
    flex-direction: column;
}


.dashboard__card-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard__card-contribution-value {
    text-align: left;
    font: normal normal bold 70px / 92px Roboto;
    letter-spacing: 0px;
    color: #404040;
    opacity: 1;
    margin: 0 0 0 20px;
}

.dashboard__card_disabled {
    pointer-events: none;
    opacity: 0.4;
}

.dashboard__card-contribution-img {
    height: 89px;
    width: 66px;
}

.dashboard__card-labels {
    font: normal normal bold 16px / 24px Roboto;
    margin: 0;
}

.dashboard__card-progress-bar {
    width: 67%;
}

.dashboard__card-my-contribution {
    margin-bottom: 30px;
}




.dashboard__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date-time-toolbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 6px #00000029;
    margin: 10px 20px 20px 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 20px;
}

.educationTermRangePicker {
    display: flex;
    gap: 10px;
}

.dashbaord__date-time-container {
    width: 80%;
    display: flex;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.dashboard__card-no-data-container {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    width: 100%;
    height: 100%;
}

.dashboard__card .switch-container{
    margin-right: 10px;
}

.dashboard__card-icons-bottom {
    position: absolute;
    bottom: 10px;
    right: 0;
    z-index: 1000;
}

.add-icon{
    width: 15px;
    height: 15px;
    background: var(--secondary-bg-color) 0% 0% no-repeat padding-box;
    border-radius: 50%;
    margin-right: 10px;
    color: var(--secondary-background-text-color) !important;
}

.add-text{
    color: #404040
}

.default-text-color{
   color: #404040 !important;
}

.dashboard__select-card-type-section{
    text-align: center;
}

.switch-container{
    min-width: 175px;
}

.requirement-widget{
    min-height: 190px!important;
    height: initial!important;
  }

.dashboard .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiTableContainer-root{
    overflow: auto !important;
    max-height: 544px !important;
}

#dashboardBtn .activeBtns .MuiSwitch-thumb {
    background-color: var(--primary-bg-color) !important;
    height: 10px !important;
    width: 10px !important;
    margin-top: 5px !important;
    margin-left: 5px !important;
  }
#dashboardBtn .activeBtns .MuiSwitch-track {
       background: #5d5d5d !important;
       opacity: 1 !important;
   }