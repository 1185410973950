.registerBackground{
  background-image: url("../../../../public/assests/backgroundimg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width:75%;
  height:100vh;
  margin:0px;
  padding:0px;
  position: fixed;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding:30px;
}
.registerBackground p{
  color:black;
}
.registration-container{
  position: relative;
  left:300px;
  width:529.04px;
  height:604.82px;
  background-image: url("../../../../public/assests/signincurve.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.topLinks{
  display: flex;
  justify-content: center;
  align-items: center;
  height:80px;
}
.topLinks a{
  margin:40px;
  text-decoration: none;
}
.d-flex{
  display: flex;
}
.align-items-center{
  align-items: center;
}
.m-2{
  margin:20px;
}
.text-center{
  text-align: center;
}
.tab{
  margin:20px;
  text-decoration: none;
  color:black;
  width:80px;
  cursor: pointer;
  border-bottom: 3px solid white;
}
.tab.active{
  color:var(--primary-bg-color);
  border-bottom: 3px solid var(--primary-bg-color);
}
.justify-content-start{
  justify-content: flex-start;
}
.flex-column{
  flex-direction: column;
}
.text-left{
  text-align: left;
}
.eyeicon{
  position: absolute;
  top:-35px;
  left:200px;
  width:30px;
}
.ml-2{
  margin-left: 70px;
  margin-right:70px;

}
.linelength{
  width:260px !important
}

.btn-register{
  width: 150px;
  height: 50px;
  font-family: "Roboto";
  background-color: var(--primary-bg-color);
  border:0;
  border-radius: 100px;
  cursor:pointer;
  font-size:20px;
  margin-left: 150px !important;
  color: var(--primary-background-text-color);
}
/* .link{
  display: block;
  margin-top:20px;
  font-size:20px;
  color:#3B4046;
} */
.justify-content-center{
  justify-content: center;
}
.activeTab{
  color:var(--primary-bg-color)!important;
}