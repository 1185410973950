.sreeram {
    margin-left: 100px;
}

.personaldetails {
    display: flex;
    gap: 11.3px;
}

.personaldetailstext {
    color: #5D5D5D !important;
    text-align: left;
    font: normal normal medium 20px/26px "Roboto";
    font-size: 19px !important;
    font-weight: 500;
    letter-spacing: 0px;
    opacity: 1;
    padding-top: 10px;
}

.applicantcreatetextfeilds {
    display: flex;
    gap: 65px;
    width: 1620px;
}

.educationdetailstext {
    margin-top: 40px;
    color: #262626 !important;
    font: normal normal medium 20px/26px "Roboto" !important;
    font-size: 20px !important;
    font-weight: 500;
    letter-spacing: 0px;
    opacity: 1;
}

.addeducation {
    display: flex;
    gap: 11px;
    cursor: pointer;
}

.addeducationdetailstext {
    text-align: left;
    font: normal normal medium 18px/24px "Roboto" !important;
    letter-spacing: 0px;
    color: #3B4046 !important;
    font-size: 18px !important;
    opacity: 1;
}

.workexperiencetext {
    text-align: left;
    font: normal normal medium "Roboto" !important;
    letter-spacing: 0px;
    font-weight: 500 !important;
    font-size: 20px !important;
    color: #262626 !important;
    opacity: 1;
    margin-top: 30px;
    margin-bottom: 15px;
}

.textfeildcss {

    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    opacity: 1;
}

.checkbox {
    display: flex;
    margin-top: 20px;
}

.checkboxstyle {
    background: #FFFFFF 0% 0% no-repeat padding-box;

    border-radius: 5px;
    opacity: 1;
    outline: none !important;
}

.checkboxtext {
    text-align: left;
    margin-left: -5px;
    font: normal normal medium "Roboto" !important;
    font-size: 18px !important;
    font-weight: 500;
    letter-spacing: 0px;
    color: #3B4046 !important;
    opacity: 1;
    margin-top: 10px;
}

.buttons {
    display: flex;
    gap: 33px;
    margin-left: 1255px;
    margin-top: 157px;
}

.savebutton {
    background: var(--primary-bg-color) 0% 0% no-repeat padding-box !important;
    width: 152px;
    height: 58px;
    color: var(--primary-background-text-color) !important;
    border-radius: 29px !important;
    opacity: 1;
}

.cancelbutton {
    height: 26px;
    width: 61px;
}

.upload_btn_icon {
    height: calc(100vh - 198px);
    text-align: center;
}

.upload_btn_icon label {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
}

.upload_btn_icon svg {
    font-size: 63px;
    color: #5d5d5d;
}

.upload_btn_icon p {
    color: #5d5d5d;
    margin: 0;
    line-height: 1;
}

.upload_btn_icon label:hover svg,
.upload_btn_icon label:hover p {
    color: var(--primary-bg-color);
}

.uploadappl {
    margin-left: 60px;
    margin-top: -28px;
}