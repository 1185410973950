.contdiv {
    width: 1415px;
    margin-top: 119px;
    margin-left: 0px;

}

.editingicon {
    flex-direction: row;
    /* justify-content:center ; */
    margin-left: 45px;
}

.type {
    /* margin-left: -280px !important;
    margin-top: 20px; */
    text-align: left;
    font: normal normal normal 20px/26px Roboto;
    color: #1A1A1A;
}

.typeselectbutton {
    /* color: #CBCBCB; */
    width: 134px;
    height: 44px;
    margin-left: 21px;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.30px solid #D1D1D1;
}

.notetextbox {
    margin-top: 25px;
    height: 264px;
    width: 100%;
    border-radius: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.30000001192092896px solid #D1D1D1;
    opacity: 1;
    outline: none !important;
    padding: 14px 18px;
    box-sizing: border-box;
    resize: none;
}

.notes-cancelsavebuttons {
    padding: 65px 0;
    float: right;
}

b {
    color: black;
}

.sidebar .notecancelbtn a{
    /* background-color: white; */
  color: red !important;
  border-radius: 10px;
  width: 6rem;
  font-family: "Roboto" !important;
  font-weight: 600 !important;
}
.notecancelbtn:hover{
    font-weight: bolder !important;
}
.savebtn {
    background-color: var(--primary-bg-color) !important;
    width: 6rem;
    height: 37px; 
    border-radius: 10px !important;
    color: var(--primary-background-text-color) !important;
    font-size: 14px !important;
}
 .notes-savebtn {
    background-color: var(--primary-bg-color) !important;
    width: 6rem;
    height: 37px; 
    border-radius: 10px !important;
    color: var(--primary-background-text-color) !important;
    font-size: 14px !important;
    margin-left:0px !important;
}
 .notes-savebtn:hover{
    background-color: var(--primary-background-text-color) !important;
    width: 6rem;
    height: 37px; 
    border-radius: 10px !important;
    color: var(--primary-bg-color) !important;
    font-size: 14px !important;
    margin-left:0px !important;
}

.savebtn:hover{
    font-weight: bolder !important;
    color:  var(--primary-background-text-color) !important;
    background-color:var(--primary-bg-color) !important ;
}
.savebtn:disabled {
    background-color: #dadada !important;
}

.usericon {
    /* padding: 30px 0px; */
    margin: 30px 0px;
    margin-left: 20px;
    width: 51px;
    height: 51px;
}

.isEven {
    color: #4F5357 !important;
    /* background-color: #F5D210; */
}

.crumb {
    padding: 10px;
    margin-left: -10px;
    font: roboto;
    color: #1A1A1A;

}


.line {
    color: #CBCBCB;
    width: 1618px;

}

.secline {
    width: 100%;
}

.jobnote {
    margin-left: 120px;
    margin-top: -120px;
    padding: 32px 0px;
    width: 97% !important;
    word-break: break-all;
}

 .createdDate {
    color: #CBCBCB;
}

.addnoteicon{
    gap: 20px;
}

.addnotetext{
font-family: "Roboto";
font-weight: 400;
letter-spacing: 0px;
color: #3B4046;
opacity: 1;
width: 93px;
/* height: 26px; */
margin-top: 6PX;
}

.addnotetext:hover{
    color: var(--primary-bg-color) !important;
}
.addnotessection{
    position: absolute;
    height:26px;
    right: 0px;
    margin-top: -80px;
    /* background-color: red; */
    cursor: pointer !important;
}
.adddocumentplusicon{
    height: 35px;
}

.contentSection footer img {
    width: 100%;
}