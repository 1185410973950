.personiconassociate {
  display: flex;
}

.breadcrumbsjobassociate {
  padding-bottom: -90px;
  margin-bottom: -18px;
  padding-left: 15px;
}

.formdropdown {
  right: 60px;
  width: 150px;
}

.containercontent {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}

.tableCompAssociate {
  margin-top: -58px;
  width: 1380px;
  margin-left: 50px;
}
.jobpostingasaphead {
  font-family: "Roboto" !important;
  font-weight: 500 !important;
  text-transform: capitalize;
  font: normal normal medium 18px/27px "Roboto" !important;
}
.jobpostingasapbodycss {
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  text-transform: capitalize;
  color: #000000 !important;
  opacity: 1 !important;
  font: normal normal normal 14px "Roboto" !important;
}

.rightsidebarbtn .sidebar {
  width: 890px !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.jobProfileWidth .sidebar {
  width: 626px !important;
}

.rightsidebarbtn .sidebarBg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}

.addappassociatesection {
  cursor: pointer !important;
  position: absolute;
  right: 57px;
  margin-top: 0px;
  height: 25px;
}
.updatestatussavebtn{
    background-color: var(--primary-bg-color) !important;
    width: 6rem;
    height: 37px;
    border-radius: 10px !important;
    color: var(--primary-background-text-color) !important;
    font-size: 14px !important;
}

.updatestatussavebtn:hover{
  font-weight: bolder !important;
  color: var(--primary-bg-color) !important;
  background-color: var(--primary-background-text-color)!important;
}