header {
  width: 100%;
  height: 71px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  /* justify-content: space-between; */
}

.previewSection img {
  width: 152px;
  padding-left: 34px;
  object-fit: contain;
}

.searchdivpos>div {
  margin-top: 20px;
  margin-left: 95px;
  /* margin-right: 130px; */
}


.SearchBar {
  border-radius: 50px !important;
  background: #fafafa !important;
  border: 1px solid #d1d1d1 !important;
  width: 300px;
  height: 40px !important;
  transition: all 0.2s ease-in-out !important;
}

.SearchBar input:before {
  content: attr(placeholder);
  position: absolute;
  left: 5px;
  top: 5px;
  white-space: nowrap;
  color: red;
  pointer-events: none;
  z-index: -1;
}

/* .innerdiv {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  min-height: calc(100vh - 715px);
  margin: 30px auto 0;
  width: 90%;
} */

.sidediv .cardPosition {
  width: 100%;
  margin-bottom: 25px;
}

.cardPosition {
  width: 23%;
  height: 296px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #D1D1D1 !important;
  border: 2px solid #ffffff;
  opacity: 1;
  border-radius: 15px !important;
  cursor: pointer;
}

.cardPosition:hover,
.cardPosition.active {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid var(--primary-bg-color);
  opacity: 1;
}

/* .adSearchPannel {
  height: 30%;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 60px;
  padding: 10px 115px 40px 90px;
  ;
  width: 88%;
}

.adSearchPannel h4 {
  margin-left: 5px;
  margin-top: 25px;
  font-weight: 400;
} */

.filterLabel {
  border: none;
  background: #F2F2F2;
  border-radius: 25px;
  height: 30px;
  padding: 2px 0px 0px 15px;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 3px 6px #00000029;
}

/* .adSearchPannelList {
  list-style: none;
  padding: 0px;
  display: flex;
  gap: 15px;
}

.adSearchPannelList li div.active {
  font-weight: bold;
  background: var(--primary-bg-color);
  border-radius: 25px;
  height: 30px;
  padding: 0px 0px 0px 15px;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 3px 6px #00000029;
}

.adSearchPannelList li div:first-child {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px 15px 0px;
}

.adSearchPannelList li div:first-child {
  display: flex;
}

.adSearchPannelList li {
  line-height: 1.6;
  margin-bottom: 15px;
} */

/* ul.listbtns {
  padding: 0;
  list-style: none;
  margin: 0px !important;
  display: flex;
}

ul.listbtns li {
  margin: 0;
  border: 1px solid #bebebe;
  display: inline;
  padding: 6px 10px;
  font-size: 14px;
  margin-right: 6px;
  border-radius: 32px;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 6px;
}

ul.listbtns li span {
  margin: 0px 5px;
}

ul.listbtns li.active {
  background: var(--primary-bg-color);
  border: none;
} */

/* .adSearchBox {
  border: none;
  background: #F2F2F2;
  border-radius: 25px;
  height: 35px;
  padding: 0px 3px 0px 15px;
  width: 12%;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 3px 6px #00000029;
}

.adSearchBox .MuiInputBase-root {
  padding: 3px 0px 0px 10px;
}

.adSearchBox:focus-visible {
  outline: none !important;

} */

.datebuttongroup button {
  border-color: #707070;
  color: #707070;
}

.datebuttongroup button:first-child {
  border-bottom-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

.datebuttongroup button:last-child {
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.dialogtxt {
  margin-bottom: 70px;
  margin-left: 85px;
}

.submitpopup {
  margin-left: 50px;
  margin-top: 90px;
}

/* .css-1fyyp8j-MuiGrid-root{
    padding-left: 15px;
  } */
/* .css-480o17-MuiGrid-root  { 
    padding-left: -20px;
  } */

  .imgSection {
    background: #fff 0 0 no-repeat padding-box;
    border: 1px solid #cbcbcb;
    margin: 20px 0 30px 40px;
    padding: 10px;
    width: 180px;
}

.typographySection {
  /* display: flex; */
  padding-left: 24px;
  /* justify-content: space-between;
  align-items: flex-start; */
  margin-bottom: 30px;
}

.typographySection label {
  font-size: 14px;
  display: block;
  font-weight: 400;
  margin-bottom: 6px;
}

.typographySection p {
  margin: 28px 0 0 0;
}

.primarySection{
  /* display: flex;
  align-items: center; */
  /* justify-content: space-between; */
  width: 266px;
  margin-bottom: 25px;
}

.typographySection p, .primarySection p{
  margin: 0;
  padding: 0;
}

.previewSection{
  border: 1px solid #bebebe;
  padding: 7px;
}


/* //////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 600px) {
  .aulflimg {
    display: none;
  }

  .searchdivpos {
    margin-top: 90px;
    margin-left: 30px !important;
  }

  .innerdiv {
    margin: 50px 32px 0 32px;
  }
}

@media screen and (max-width: 375px) {
  .aulflimg {
    display: none;
  }

  .searchdivpos {
    margin-top: 90px;
    margin-left: 60px !important;
  }

  .innerdiv {
    margin-left: 32px;
  }

  .cardPosition {
    width: 300px !important;
  }

  .SearchBar {
    width: 80% !important;
  }
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////   */
/* JobSelectpage.css */

.sidediv {
  margin-top: 50px !important;
  height: 1275px;
  width: 25%;
  overflow-x: hidden;
  padding: 12px 21px 4px 4px;
  position: sticky !important;
}

.ques {
  display: none;
}

.cardsPosition {
  width: 100% !important;
  height: 296px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #d1d1d1 !important;
  border: 2px solid #ffffff;
  opacity: 1;
  border-radius: 15px !important;
  margin-bottom: 20px !important;
  cursor: pointer;
}

.cardsPosition:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid var(--primary-bg-color);
  opacity: 1;
}

.cardsPosition.active {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid var(--primary-bg-color);
  opacity: 1;
}

.btn-submit-candidate {
  width: 114px;
  height: 48px;
  font-family: "Roboto";
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  text-transform: capitalize !important;
  color: var(--primary-background-text-color);
  background-color: var(--primary-bg-color);

}

.btn-submit-candidate:hover {
  font-weight: bold !important;
  color: var(--primary-bg-color);
  background-color: var(--secondary-bg-color);
}

.txt-submissions {
  color: #1a1a1a;
  /* position: relative; */
}

.sidesubmitbtn {
  width: 100%;
}

.displaycard {
  margin: 30px 0 0 60px;
  width: 1210px;
  display: flex;
}

.square {
  background: #7a7a7a 0% 0% no-repeat padding-box;
  width: 43px;
  height: 43px;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vert-line {
  width: 0px;
  height: 114px;
  border: 0.28299999237060547px solid #7a7a7a;
  margin-top: 114px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary-bg-color);
  margin-left: -26px;
  margin-top: 130px;
  color: var(--primary-background-text-color);
}

.squarelinedot {
  margin: 30px 0 0 30px !important;
  width: 50px;
}

.submissionbtn {
  margin: 30px 0 0 0;
}

.displaytext {
  margin-left: -26px;
}

.documentspost {
  width: 1450px;
  margin-left: 80px;
}

.uploadSection {
  display: inline-block !important;
  padding: 18px 30px !important;
  box-shadow: none !important;
  border: 2px dashed var(--primary-bg-color) !important;
  border-radius: 10px !important;
  text-transform: inherit !important;
  font: normal normal normal 18px/24px "Roboto" !important;
  /* color: #9e9e9e !important; */
  width: 340px;
  margin: 0 auto 18px !important;
  background-color: white !important;
  cursor: pointer !important;
  /* margin-top: 50px !important; */
  height: 66px;
}

.uploadSection:hover {
  background-color: var(--secondary-bg-color) !important;
  display: none;
  border: solid !important;
  width: 340px;
  height: 66px;
}

.uploadSection:hover span b:last-child {
  display: none;
}

.uploadSection span:first-child {
  font: normal normal bold 18px "Roboto" !important;
  color: var(--primary-bg-color);
}

.uploadSection span+span {
  display: none;
}

.cancelBtn {
  background-color: white;
  color: red !important;
  border-radius: 15px;
  width: 6rem;
  font-family: "Roboto" !important;
  font-weight: 600 !important;
}

.cancelBtn:hover {
  font-weight: bolder !important;
}

.saveBtn {
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-background-text-color) !important;
  border-radius: 15px !important;
  width: 6rem !important;
  height: 37px !important;
}

.saveBtn:hover {
  font-weight: bolder !important;
}

.listofUploads {
  list-style: none;
  text-align: right;
  font-size: 15px;
  height: 60px;
  overflow-y: auto;
  padding: 0;
  border: 1px solid #efefef;
  margin-top: 30px;
}

.listofUploads li {
  line-height: 2.4;
  display: flex;
  align-items: baseline;
  margin: 2px 0;
  background-color: #fff;
  padding: 0 25px;
}

.listofUploads li:nth-child(even) {
  background-color: #f5f5f5;
}

.listofUploads li span svg {
  color: red;
  font-size: 17px;
  vertical-align: middle;
  padding: 8px;
  cursor: pointer;
}

.listofUploads li p {
  margin: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 85%;
  text-align: left;
}

.actionBtns {
  text-align: right;
  margin-top: 64px;
}

.actionBtns button:first-child {
  /* margin-right: 22px; */
}

.upcheckbox {
  margin-left: -320px;
  margin-top: 40px;
  display: flex;
  color: var(--primary-bg-color);
  justify-content: center;
}

.txt {
  color: #3b4046;
}

.css {
  top: 0px;
  width: 800px;
  height: 1260px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #d8d6d6 0% 0% no-repeat padding-box;
  border-radius: 20px 0px 0px 20px;
  opacity: 1;
  margin-left: 1100px;
  height: 930px !important;
  position: absolute;
}

.closeup {
  margin-left: 630px;
  margin-top: -95px;
}

.applybtnupcs {
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-background-text-color) !important;
  width: 150px;
  height: 50px;
  border-radius: 42px !important;
  opacity: 1;
  margin-left: -665px !important;
  margin-top: 30px !important;
  text-transform: capitalize;
  font: normal normal normal 16px 'Roboto';
}

.applybtnupcs:hover {

  background-color: var(--secondary-bg-color) !important;
  color: var(--primary-bg-color) !important;
  font-weight: bolder !important;
  width: 150px;
  height: 50px;
  border-radius: 42px !important;
  opacity: 1;
}

.textfieldbtncs {
  margin-top: -80px !important;
  margin-left: -30px !important;
}

.textfieldbtncs p {
  margin: 0;
}

.rightimgBox {
  opacity: 1;
  position: fixed;
  bottom: 0;
  margin-left: -50px;
  z-index: -1;
}

.submitpopup {
  width: 456px;
  height: 195px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  top: 41% !important;
  left: 36% !important;
  position: absolute !important;
}

.submitpopup img {
  margin-left: 200px;
  margin-top: 50px;
}

.submitpopup:focus-visible {
  border: none !important;
}

.submitpopuptext {
  position: relative !important;
  margin: 20px 0 0 100px !important;
  color: #1a1a1a !important;
  font-family: "Roboto";
  font-size: 19px !important;
  opacity: 1;
  font-weight: 400 !important;
}

.greentickicon {
  margin: 40px 0 0 200px !important;
  position: relative !important;
}

.upcheckbox .MuiCheckbox-root.Mui-checked {
  color: var(--primary-bg-color) !important;
}

.QueOptionSection .MuiCheckbox-root.Mui-checked {
  color: var(--primary-bg-color) !important;
}



.applybtnupcss {
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-background-text-color) !important;
  width: 150px;
  height: 50px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 42px !important;
  opacity: 1;
  margin-left: -690px !important;
  margin-top: 30px !important;
}

.applybtnupcss:hover {
  background-color: var(--secondary-bg-color) !important;
  color: var(--primary-bg-color) !important;
  border-radius: 0px !important;
  width: 150px;
  height: 50px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 42px !important;
  opacity: 1;
  margin-left: -690px !important;
  margin-top: 30px !important;
}

.applybtnupcsss {
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-background-text-color) !important;
  width: 150px;
  height: 50px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 42px !important;
  opacity: 1;
  margin-left: -660px !important;
  margin-top: 30px !important;
}

.applybtnupcsss:hover {
  background-color: var(--secondary-bg-color) !important;
  color: var(--primary-bg-color) !important;
  border-radius: 0px !important;
  width: 150px;
  height: 50px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 42px !important;
  opacity: 1;
  margin-left: -660px !important;
  margin-top: 30px !important;
}


.applybtnupcss b {
  text-align: left;
  font: normal normal normal 16px 'Roboto';
  letter-spacing: 0px;
  opacity: 1;
  text-transform: capitalize;
}

.questionPannelSection .sidebar {
  width: 916px !important;
}

/* .applybtnupcs.applynow {
      margin-left: 0 !important;
    } */

.QueOptionSection {
  text-align: left;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin: 32px 0;
  position: relative;
}

.QueOptionSection h4 {
  font-size: 17px;
  font-weight: 500;
  background: #fff;
  display: inline-block;
  padding: 0 4px;
  margin: -7px 0 0 8px !important;
  position: absolute;
  color: #262626;
}

.QueOptionSection ul {
  list-style: none;
  padding: 0;
}

.QueOptionSection ul li {
  display: inline-block;
  width: 50%;
}

.textup {
  list-style: none;
  text-align: right;
  font-size: 15px;
  height: 650px;
  overflow-y: auto;
  padding: 0;
  /* border: 1px solid #efefef; */
  padding-right: 15px;
}

.jobBoardboldlabel {
  position: relative;
}

.jobBoardboldlabel label {
  font-weight: 500 !important;
  font-size: 16.8px !important;
  color: #000000 !important;
  margin-left: -3px;
}

#jobBoardportal .docsupporttext {
  color: #9e9e9e;
  font-family: Roboto;
  font-size: normal;
  font-size: 15px;
  letter-spacing: 0;
  margin-left: 12px !important;
  margin-top: -10px !important;
}

.jobBoardPortalBody .font-weight-bold1{
  color: #3b4046;
  font-family: "Roboto";
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
}


/* ///////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 905px) {

  .sidediv>div div,
  .sidediv>div p {
    font-size: 14px !important;
  }

  .sidediv>div div:nth-child(2) {
    font-size: 19px !important;
  }
}

@media screen and (max-width: 760px) {
  .sidediv {
    display: none
  }

  .sidesubmitbtn {
    width: 100%;
  }

  .selectionCards,
  .footercomp {
    display: none;
  }
}

@media screen and (min-width: 761px) {
  .sidediv {
    display: block;
  }

  .sidesubmitbtn {
    width: 100%;
  }

  .selectionCards {
    display: none;
  }
}

/* @media screen and (max-width: 902px) {
      .forms-fields-container > div{
        display: flex;
        flex-direction: column !important;
        flex-wrap: wrap;
        align-items: center !important;
       
        width: 100%;
        gap: 10px;   
      }
    } */

/* @media screen and (max-width: 699px) {
      .upcheckbox{
        margin-left: 223px !important;
        margin-top: 0px;
      }
    } */
/* @media screen and (max-width: 663px) {
      .upcheckbox{
        margin-left: 293px !important;
      }
    
    .docsupporttext {
      margin-top: -5px;
      margin-left: 165px;
    }
    } */

/* @media screen and (max-width: 659px) {
      .forms-fields-container > div{
        margin-left: 130px;
      }
    } */
/* //////////////// selected job details ////////////// */
@media screen and (max-width: 450px) {
  .aulflimg {
    display: none;
  }

  .squarelinedot {
    display: none !important;
  }

  .sidesubmitbtn {
    flex-direction: column;
  }

  .submissionbtn {
    margin: 50px 100px 0 100px;
  }

  .displaycard {
    width: 75%;
  }

  .btn-submit-candidate {
    width: 100%;
    height: 48px;
    border-radius: 10px;
  }
}

@media screen and (max-width: 331px) {
  .submissionbtn p {
    font-size: 12px !important;
  }
}

/* ////////////////////////////////////// */

@media screen and (max-width: 430px) {
  .contentSection h6 {
    display: none;
  }

  /* .contentSection {
        margin-left: 480px;
        align-items: center;
        align-content: center;
      } */

  /* .upcheckbox{
        margin-left: 0px !important;
        margin-top: 80px !important;
      } */

  /* .docsupporttext {
      margin-top: -5px;
      margin-left: 10px;
    } */
  /* .sidebar .contentSection {
      padding: 20px 0px !important;
    } */
  .rightimgBox {
    display: none;
  }

  .ques {
    display: block;
    color: var(--primary-bg-color);
    width: 100px;
    margin-bottom: 29px;
    margin-top: -39px;
  }
}

@media screen and (max-width: 375px) {
  .contentSection h6 {
    display: none;
  }

  /* .contentSection {
        margin-left: 480px;
        align-items: center;
        align-content: center;
      } */
  /* .forms-fields-container > div{
        align-items: center !important;
        width: 140% !important;
        margin-left: -50px;
      } */
  /* .upcheckbox{
        margin-left: 75px !important;
        margin-top: -10px !important;
      } */
  /* .docsupporttext {
      margin-top: -5px;
      margin-left: 90px;
    } */
  /* .sidebar .contentSection {
      padding: 20px 0px !important;
    } */
  .rightimgBox {
    display: none;
  }

  /* .ques{
      display: block;
      color: var(--primary-bg-color);
      margin:-80px 140px 0px 0px !important;
    } */
  /* .applybtnupcs {
      margin: 0px 0 0px 60px !important;
    } */
}

@media screen and (max-width: 937px) {
  .questionPannelSection .sidebar {
    width: 90% !important;
  }
}

@media screen and (max-width: 455px) {
  .uploadSection b:nth-child(2) {
    display: none;
  }
}

@media screen and (max-width: 525px) {
  .rightimgBox {
    display: none;
  }
}

.multilinetextWrap {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.customRadioGroup,
.customFormGroup {
  display: inline-block !important;
  width: 50%;
  margin-top: 18px;
}

.customRadioGroup .MuiFormControlLabel-root,
.customFormGroup .MuiFormControlLabel-root {
  width: 49%;
  margin: 0;
}

.customErrorMsg {
  color: red;
  position: absolute;
  margin-top: -27px;
  font-size: 13px;
}

.cancelBtn.Mui-disabled {
  background: transparent !important;
}

.filterbtn:disabled {
  background-color: #d0d0d0 !important;
}

.filterbtn {
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-background-text-color) !important;
  border-radius: 20px !important;
  cursor: pointer !important;
}

.filterbtn:hover {
  background-color: var(--secondary-bg-color) !important;
  color: var(--primary-bg-color) !important;
  cursor: pointer !important;
}

.displaytext * {
  font-size: small;
}

.footercomp {
  background: var(--secondary-bg-color) 0% 0% no-repeat padding-box;
  border-radius: 30px 30px 0px 0px;
  opacity: 1;
}

.footercomp ul {
  display: flex;
  list-style: none;
  justify-content: space-between;
  padding: 17px 61px 4px;
}

.footercomp ul li {
  text-align: center;
  cursor: pointer;
  color: #fff;
  font-size: 13px;
}

.footercomp ul li .MuiLink-root {
  text-decoration: none
}

.yellowbottom {
  background: var(--primary-bg-color);
  text-align: center;
  font-size: 13px;
  padding: 5px 0;
  color: var(--primary-background-text-color) !important;
}

.menulist{
  margin: 0 0 0 33px;
  line-height: 5;
  font-weight: bold;
  font-size: 14px;
}

#jobBoardportal .jobBoardportaluploadresume {
  width: 375px;
  text-align: center;
  padding: 12px 30px !important;
  display: block!important;
  box-shadow: none !important;
  border: 2px dashed var(--primary-bg-color) !important;
  border-radius: 10px !important;
  text-transform: inherit !important;
  font: normal normal normal 18px/24px "Roboto" !important;
  margin-bottom: 5px !important;
  background-color: white !important;
  cursor: pointer !important;
  margin-left: 0px !important;
}

#jobBoardportal .jobBoardportaluploadresume:hover {
  background-color: #000000 !important;
  display: none;
  border: 2px solid #3b4046 !important;
  text-align: center;
}

#jobBoardportal .jobBoardportaluploadresume:hover span b:last-child {
  display: none;
}

#jobBoardportal .jobBoardportaluploadresume span:first-child {
  font: normal normal bold 18px "Roboto" !important;
  color: var(--primary-bg-color);
}

#jobBoardportal .jobBoardportaluploadresume span + span {
  display: none;
}

#jobBoardportal .filelistupload {
  list-style: none;
  text-align: right;
  font-size: 15px;
  height: 50px;
  overflow-y: auto;
  padding: 0;
  border: none;
}

#jobBoardportal .filelistupload li {
  line-height: 2.4;
  display: flex;
  align-items: baseline;
  margin: 2px 0;
  background-color: #fff;
  padding: 0 5px;
}

#jobBoardportal .filelistupload li:nth-child(even) {
  background-color: #f5f5f5;
}

#jobBoardportal .filelistupload li span svg {
  color: red;
  font-size: 17px;
  vertical-align: middle;
  padding: 8px;
  cursor: pointer;
}

#jobBoardportal .filelistupload li p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 53%;
  text-align: left;
}

#jobboard.boldSelectlabel {
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #262626 !important;
  border-color: #e0e0e0 !important;
  background: #f3f3f3;
}
#jobboard.boldSelectlabelCreate {
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #262626 !important;
  border-color: #e0e0e0 !important;
  background: #ffffff !important;
}

.toggleBtn .Mui-checked .MuiSwitch-thumb{
  margin-top: 6px !important;
  left: -2px !important;
  position: relative;
}

.toggleBtn .Mui-checked + span.MuiSwitch-track{
  height: 18px;
  border-radius: 10px;
}

.toggleBtn .MuiSwitch-thumb{
  margin-top: 6px !important;
      left: 10% ;
    position: relative;
}

.toggleBtn .MuiSwitch-track{
  height: 18px;
  opacity: 1 !important;
  border-radius: 10px;
}