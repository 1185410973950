.internal-external-wrapper {
  width: 100%;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  padding: 10px;
  opacity: 1;
}

.internal-external-wrapper_headers,
.internal-external-wrapper_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.internal-external-wrapper_headers_internal,
.internal-external-wrapper_headers_external,
.internal-external-wrapper_body_internal,
.internal-external-wrapper_body_external {
  width: 47%;
  color: #3e3e3e;
  opacity: 1;
  font-size: 12px;
  font-weight: bold;
  background: #f7f7f7 0% 0% no-repeat padding-box;
}

.internal-external-wrapper_headers_relation,
.internal-external-wrapper_headers_actions,
.internal-external-wrapper_body_relation,
.internal-external-wrapper_body_actions {
  width: 5%;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.internal-external-wrapper_body_internal,
.internal-external-wrapper_body_external {
  font-size: 14px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 5px;
  font-weight: normal;
}

.internal-external-wrapper_body_actions {
  margin-left: 5px;
}

.internal-external-wrapper_body_actions svg {
  cursor: pointer;
  color: var(--danger-color) !important;
}

.internal-external-wrapper_body_relation_svg {
  width: 10px;
  height: 10px;
}

.internal-external-wrapper_body_internal .MuiFormControl-root input,
.internal-external-wrapper_body_external .MuiFormControl-root input {
  padding: 0 0 0 3px;
  font-size: 14px;
}

.internal-external-wrapper_add_more {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.internal-external-wrapper_add_more .MuiButtonBase-root {
  color: #3B4046;
}

.internal-external-wrapper_body_internal .Mui-error .MuiOutlinedInput-notchedOutline,
.internal-external-wrapper_body_external .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}