.stepsSection {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  min-height: calc(100vh - 360px);
  position: relative;
  padding-bottom: 30px;
  padding-top: 1px;
}

.customSteps {
  background: var(--primary-bg-color);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px 10px 0px 0px;
  height: 60px !important;
  padding: 8px 0px 0px 40px;
  width: 940px;
  color: var(--primary-background-text-color);
}

.customSteps ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.customSteps li {
  list-style: none;
  padding: 0;
  width: 223px;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin-right: 13px;
}

.customSteps li span:first-child {
  border: 1px solid;
  width: 20px;
  display: inline-block;
  margin-right: 10px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: 13px;
  line-height: 1.7;
  background-color: #fff;
  color: var(--primary-bg-color)!important;
  font-weight: 600;
}

/* .customSteps li span {
  cursor: pointer;
} */

.customSteps li:before {
  content: "";
  border-bottom: 2px solid #fff;
  width: 100%;
  display: block;
  position: absolute;
  height: 11px;
  margin-left: 38px;
}

.customSteps li span:last-child {
  background-color: var(--primary-bg-color);
  position: absolute;
  color: #fff!important;
  z-index: 1;
  padding: 2px 9px 2px 0;
}

.customSteps li:last-child:before {
  border: none;
}

.customSteps li:after {
  content: ">";
  position: absolute;
  z-index: 1;
  right: 0;
  margin-top: 1px;
  color: #fff;
  font-size: 18px;
}

.customSteps li:last-child:after {
  content: "";
}

.customSteps li.active span:first-child {
  background-color: var(--secondary-bg-color);
  border-color: #3B4046;
  font-weight: 600;
}

.customSteps li.active span:last-child {
  background-color: var(--primary-bg-color);
  color: var(--primary-background-text-color)!important;
  font-weight: 600;
}

.customSteps li.active:after, 
.customSteps li.active:before {
  border-bottom: 2px solid #3B4046;
  color: #3B4046;
}

.customSteps li:last-child.active:before{ 
  border: none!important;
}

.stepsAction button {
  background: #d3d3d3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 17px 30px;
  border: none;
  cursor: pointer;
  font: normal normal normal 15px/26px Roboto;
}

.stepsAction {
  padding: 0px 40px 25px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.stepsInnerArea{
  position: relative;
}

.stepsActionButtons{
  position: absolute;
  right: 0;
  margin-top: -104px;
  font-weight: 500;
  border-radius: 10px;
  font-size: 16px;
}

.stepsActionButtons button:last-child{
  margin-left: 20px;
}

.docSection{
  padding: 0 30px;
  margin-top: 10px;
}

.stepsInnerArea .subHeading{
  font-size: 16px;
  font-weight: 400;
}

.docSection .listofUploads li p {
  width: 486px;
  margin-right: 11px;
}

.stepsInnerArea .postingQuestion{
  padding: 11px 27px 27px;
}

.stepsInnerArea .addquestionicontext{
  top: -62px;
  right: 3px;
}

.stepsInnerArea .nodatasection{
  display : none;
}

.portalsSection{
  padding: 0 35px 35px;
}

.portalsSection p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 700px;
}

.customSteps li#active1.active:before, .customSteps li#active1.active:after {
  border-color: #fff;
  color: #fff;
}