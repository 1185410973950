/* eslint-disable react/prop-types */
.SearchBar {
  border-radius: 50px !important;
  background: #fafafa !important;
  border: 1px solid #d1d1d1 !important;
  width: 330px;
  height: 40px !important;
  transition: all 0.2s ease-in-out !important;
}

.SearchBar input:before {
  content: attr(placeholder);
  position: absolute;
  left: 5px;
  top: 5px;
  white-space: nowrap;
  color: red;
  pointer-events: none;
  z-index: -1;
}

@keyframes scrollText {
  to {
    transform: translateX(calc(100px - 300px));
  }
}

/* hide the before on focus */
.SearchBar:focus-within:before {
  visibility: hidden;
}

.SearchBar input::placeholder,
.SearchBar input:-ms-input-placeholder,
.SearchBar input::-ms-input-placeholder,
.rightnavsearchbar input::placeholder,
.rightnavsearchbar input:-ms-input-placeholder,
.rightnavsearchbar input::-ms-input-placeholder {
  text-indent: -240px !important;
  color: #6f6f6f !important;
  /* font-size: 14px !important; */
  display: inline-block;
  position: relative;
  z-index: 0;
  animation: 4s scrollText forwards;
  overflow: hidden;
}

.SearchBar:hover input::placeholder,
.SearchBar input:active::placeholder,
.SearchBar input:focus::placeholder {
  color: #000 !important;
}

.SearchBar input {
  font-size: 13px !important;
}

.customizedTable thead th {
  background: var(--secondary-bg-color) !important;
}

.customizedTable .Mui-checked {
  color: var(--primary-bg-color) !important;
}

.downloadicon,
.deleteicon ,
.viewicon ,
.documentdownloadicon {
  position: relative;
}

.downloadicon:hover:after
 {
  color: var(--primary-bg-color);
  fill: var(--primary-bg-color);
  content: "download";
  position: absolute;
  font-size: 9px;
  top: 20px !important;
  margin-top: -5px;
  text-transform: capitalize !important;
}
.downloadicon:hover:after,
.downloadicon:hover svg {
  content: "download";
  color: var(--primary-bg-color) !important;
  fill: var(--primary-bg-color) !important;
}
.deleteicon:hover:after  {
  color: var(--primary-bg-color);
  content: "Delete";
  position: absolute;
  font-size: 9px;
  top: 35px !important;
  margin-top: -5px;
  text-transform: capitalize !important;
}
.viewicon:hover:after {
  color: var(--primary-bg-color);
  fill: var(--primary-bg-color);
  content: "view";
  position: absolute;
  font-size: 9px;
  top: 35px !important;
  margin-top: -5px;
  text-transform: capitalize !important;
}
.documentdownloadicon:hover:after {
  color: var(--primary-bg-color);
  fill: var(--primary-bg-color);
  content: "download";
  position: absolute;
  font-size: 9px;
  top: 35px !important;
  margin-top: -5px;
  text-transform: capitalize !important;
}
.downloadicon:hover,
.deleteicon:hover,
.documentdownloadicon:hover,
.viewicon:hover {
  background-color: transparent !important;
}

.viewicon svg {
  height: 23px;
  width: 28px;
  fill: #3B4046;
  margin-top: -12px;
}

.documentdownloadicon svg {
  height: 23px;
  width: 28px;
  fill: #3B4046;
  margin-top: -12px;
}



.viewicon svg:hover path{
  fill: var(--primary-bg-color);
}

.deleteicon:hover:after,
.deleteicon:hover svg {
  content: "delete";
  color: #ff0000 !important;
  fill: #ff0000 !important;
}
.documentdownloadicon:hover:after,
.documentdownloadicon:hover svg path {
  content: "download";
  color: var(--primary-bg-color) ;
  fill: var(--primary-bg-color) ;

}
.viewicon:hover:after,
.viewicon:hover svg path {
  content: "view";
  color: var(--primary-bg-color) ;
  fill: var(--primary-bg-color) ;

}



/* 
.deleteicon:hover svg {
  height: 23px;
  width: 28px;
} */

/* .deleteicon:hover {
  content: "delete";
  margin-top: 5px;
} */



.downloadicon svg {
  height: 23px;
  width: 28px;
}



.downloadicon svg {
  height: 23px;
  width: 28px;
  margin-top: -12px;
  fill:#3B4046;
}

.deleteicon svg {
  height: 24px;
  width: 28px;
  margin-top: -12px;
  fill:#3B4046;
}

.viewicon:hover img{
  height: 23px;
  width: 28px;
  fill: var(--primary-bg-color);
  color:  var(--primary-bg-color);
}
/* .documentdownloadicon:hover img {
  height: 23px;
  width: 28px;
  fill: var(--primary-bg-color) !important;
  color:  var(--primary-bg-color) !important;
} */




.downloadicon,
.deleteicon {
  cursor: pointer;
}

.modal {
  color: #131413 !important;
}

.textttt {
  margin-top: 300px;
  margin-left: 750px;
  font-family: "Roboto" !important;
  font-weight: 400;
  background-color: transparent !important;
  color: aliceblue !important;
  opacity: 0.87 !important;
  font-size: medium;
}

.cancelbutton {
  background-color: transparent !important;
  color: aliceblue !important;
  margin-left: 750px !important;
}

.deletebutton {
  background-color: transparent !important;
  color: red !important;
}

.closebutton {
  background-color: transparent !important;
  color: red !important;
  margin-left: 1600px !important;
  height: "200px" !important;
  width: "200px" !important;
  padding-top: 50px !important;
}

.postingdocumentsicons svg {
  width: 28px;
  padding-bottom: 2px;
}

.postingdocumentsicons button {
  padding: 6px 7px 2px !important;
  min-width: inherit !important;
}

.atsColumn {
  width: 70px;
  display: inline-block;
}

.atsColumn small:hover {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  color: var(--primary-bg-color);
}

.atsColumn small img {
  margin-right: 3px;
}

.nodatasection {
  text-align: center;
  font-size: 18px;
  margin: 25px -1px;
}

.nodatasection img {
  display: block;
  margin: 0 auto 19px;
}

.addRow {
  float: right;
  margin-top: -46px !important;
  padding: 0 !important;
}

.addRow:hover svg {
  color: red !important;
  cursor: pointer !important;
}

.customizedTable th ,
.customizedTable td {
  height: 42px !important;
  align-content: center;
}

.customizedTable ,.MuiTableCell-body {
  font-size: 14px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.nodataSection {
  margin: 0 auto;
  height: 25vh !important;
}

/* .MuiTableCell-root {
  border-right: 1px solid #E8E8E8;
} */
.MuiTableRow-root th{
  border-right: none;
}

.actionSection{
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  flex: 1 1 100%;
  min-width: 190px !important;
}

.actionSectionAssocReject {
  display: flex;
  height: 100px;
  align-items: center;
  flex: 1 1 100%;
}
.actionSectionAssocReject .searchdivcss{
  height: 48px;
  margin-top: 5px;
  width: 32%;
  text-align: -webkit-left; 
}

.actionSectionAssoc{
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  flex: 1 1 100%;
}
.actionSectionAssoc .addcomponent{
  order: 2;
}
.actionSectionAssoc .searchdivcss{
  order: 1;
  height: 48px;
  margin-top: 5px;
  width: 32%;
  text-align: -webkit-left;
}
.actionSectionAssoc .actionDropdowncss{
  order: 3;
}

.searchdivcss{
  height: 48px;
  margin-top: 5px;
  width: 35%;
  text-align: -webkit-right; 
}
.addcomponent{
  width: 27%; 
  text-align: right; 
  margin-top: 18px
}

.percentbar {
  display: inline-flex;
  align-items: center;
}
.percentbar span {
  display: flex;
  width: 80px;
}
.percentbar p {
  margin-left: 10px;
  width: 15px;
}

.statusDropdown {
  position: relative;
  width: 100%;
}

.pdficon {
  width: 20px;
  height:20px;
}
.documentTypecss {
display:flex;
align-items: center;
justify-content: center;
gap:3px;
}

.progress .MuiLinearProgress-bar1Determinate{
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 1.15) 6%, transparent 0%, transparent 100%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent) !important;
  background-size: 16px 40px !important;
}

/* //////////////////////////////////////////////////// */

@media screen and (max-width: 1050px){
  #mainLayout .actionSection{
  display: flex;
  flex-direction: column-reverse;
  align-items: end;
  margin:40px 0px 10px 0px;
  }
  #mainLayout .actionSection .MuiFormControl-root{
  width: 0px !important;
  right: 240px;
  }
  #mainLayout .searchdivcss{
    margin: -20px 60px 10px;
    width: 0px !important;
    right: 268px;
    position: relative;
  }
}

.screeningQuestionsCountAlign {
  text-align: left !important;
  padding-left: 8%!important;
}

.screeningQuestionsCountAlign img{
    vertical-align:middle;
    margin-left: 8px;
}

.insOptionsSection {
  background: #ffffff7a;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 8px;
}

.insOptionsSection input {
  background-color: initial;
  border: 1px solid #d3d3d3;
  padding: 2px;
  width: 97%;
}

.insOptionsSection ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.insOptionsSection ul li {
  border: 1px solid #D3D3D3;
  padding: 4px 8px;
  min-width: 81px;
  display: inline-flex;
  border-radius: 5px;
  justify-content: space-between;
}

.insOptionsSection ul li span {
  color: red;
  cursor: pointer;
  margin-left: 5px;
  font-size: 10px;
  line-height: 2;
}

li.defList {
  background: #f0f0f0;
  cursor: not-allowed;
}
.selectData{
  margin: 15px 0px 15px 15px;
  text-align: center;
  color: #000000DE;
  font-size: 13px;
  height: 35px;
  width: 90px;
}

.radiobtnActive{
  margin-bottom: 7px !important;
  margin-left: -3px !important;
}

.rowHeight{
  min-height: 40px !important;  
  align-content: space-around !important;
}

.educationTable td.MuiTableCell-body{
   word-break: break-all;
   vertical-align: middle !important;
 }

 .educationTable .educationTableWorkExp td.MuiTableCell-body{
  word-break: break-all;
  vertical-align: initial !important;
}

.checkboxapplicants{
  padding: 0px !important;
}

.countryclass {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.countryflag{
  height: 20px;
  width: 20px;
}
