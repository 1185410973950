
.innerdivhelp {
    display: flex;
    gap: 28px;
    flex-wrap: wrap;
    min-height: calc(100vh - 715px);
    margin: 30px 20px;
    width: 97%;
  }
.innerdivhelppreview {
    display: flex;
    gap: 26px;
    flex-wrap: wrap;
    min-height: calc(100vh - 715px);
    margin: 30px 20px;
    width: 97%;
  }

    .cardPositionVideo {
    width: 100% ;
    height: 86% !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #D1D1D1 !important;
    /* border: 2px solid #ffffff; */
    opacity: 0.65;
    border-radius: 15px !important;
    cursor: pointer;
  }

  .cardPositionVideo:hover {
    opacity: 1;
  }

  .videoCards{
    text-align: center;
    color: #3B4046;
    width: 22%;
  }

  .cardPositionSuggestion {
    width: 100% !important;
    height: 140px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #D1D1D1 !important;
    /* border: 2px solid #ffffff; */
    opacity: 0.65;
    border-radius: 15px !important;
    cursor: pointer;
  }

  .cardPositionSuggestion:hover {
    opacity: 1;
  }

  #helpsection .sidebar {
    width: 50%;
  }

  .videopreview {
    border-radius: 15px;
    border: none;
  }

  .playicon {
    z-index: 1 !important;
    position: relative;
    margin-top: 32%;
  }

  .cardPositionVideo:hover .playicon {
    content: url(../../../public/assests/img/playicon.svg);
  }

  .playiconRelated {
    z-index: 1 !important;
    position: relative;
    margin-top: 21%;
  }
  .cardPositionSuggestion:hover .playiconRelated {
    content: url(../../../public/assests/img/playicon.svg);
  }

  .videotag{
    z-index: 0 !important;
    position: relative;
    margin-top: -150px;
    width: 100%;
  }
  
  .relatedText{
    /* font: normal normal bold 20px/20px Nunito; */
    font-size: 16px;
    color: #3B4046;
    text-align: initial;
  }