/* Login old CSS  */
.BGImg1 {
  display: flex;
  /* position: absolute; */
  top: 0%;
  align-items: flex-start;
  justify-content: inherit;
  width: 85%;
  position: absolute;
}


.registerBackground1 {
  background-repeat: no-repeat;
  width: 75%;
  height: 100vh;
  margin: 0px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 30px; */
  background-color: #E4E4E4 !important;
}

.registerBackground1 .BGImg2 {
  left: 20%;
  position: relative;
  /* top: 25%; */
}


.registerBackground p {
  color: black;
}

.hirewinglogologin {
  width: 150px;
  height: 58px;
  margin-top: 7% !important;
}

.registration-container1 {
  /* position: relative;
  left: 82%;
  width: 529.04px;
  height: 604.82px;
   background-image: url("../../../../public/assests/registercurve.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: -25%; */
  position: absolute;
  right: -15%;
  width: 428.54px;
  /* height:604.82px; */
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 80px;
  box-shadow: 0px 3px 6px #00000029;
  /* top: -25%;  */
}

.topLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  /* background-color: red !important */
  ;
}

.linewidth {
  width: 100%;
  height: 63px;
}

.topLinks a {
  margin: 40px;
  text-decoration: none;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.m-2 {
  margin: 20px;
}

.text-center {
  text-align: center;
}

.tab {
  margin: 20px;
  text-decoration: none;
  color: black;
  width: 80px;
  cursor: pointer;
  border-bottom: 3px solid white;
}

.tab.active {
  color: var(--primary-bg-color);
  border-bottom: 3px solid var(--primary-bg-color);
}

.justify-content-start {
  justify-content: flex-start;
}

.flex-column {
  flex-direction: column;
}

.text-left {
  text-align: left;
}

.eyeicon {
  position: absolute;
  top: -35px;
  left: 200px;
  width: 30px;
}

.ml-2 {
  margin-left: 17px !important;
  margin-right: 53px !important;

}



.rembtext {
  display: block;
  width: 110px;
}

.remforget {
  display: flex;
  margin-left: 5px;
  align-items: center !important;

}

.rembtext:hover {
  font-weight: bolder !important;
}

/* .btnSection .btn-submitlogin {
  width: 150px;
  height: 50px;
  font-family: "Roboto";
  color: var(--primary-background-text-color);
  background-color: var(--primary-bg-color);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  font-size: 16px !important;
  text-transform: capitalize !important;

} */

/* .btn-submitlogin:hover {
  background-color: var(--primary-background-text-color) !important;
  color: var(--primary-bg-color) !important;

} */

.linktohome {
  display: block;
  margin-top: 20px;
  font-size: 20px;
  color: #3B4046;
  margin-left: 0px !important;
}

.justify-content-center {
  justify-content: center;
}

.forgotlink {
  text-decoration: none;
  color: black;
  margin-left: 40px;
}

.forgotlink:hover {
  font-weight: bolder !important;
}

.activeTab {
  color: var(--primary-bg-color) !important;
}

.btnSection {
  text-align: center;
  width: 100%;
  margin-top: -5px;
}

.autoLoginSection {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.autoLoginSection div {
  width: 170px;
  height: 3px;
  background: #e1e1e1;
  display: block;
  border-radius: 25px;
  margin-top: 5px;
  overflow: hidden;
}

.autoLoginSection div span {
  width: 90px;
  background: var(--primary-bg-color);
  height: 100%;
  margin-left: -90px;
  animation: lineMoving 1.5s linear infinite alternate;
  display: block;
  color: var(--primary-background-text-color) !important;
}

.signtxt {
  margin-left: 30px;
  margin-top: 25px;
  margin-bottom: -15px;
}

.privacytxt {
  margin-left: 30px;
  cursor: pointer;
  color: #3B4046 !important;
}

.andtxt {
  margin-left: 137px;
  margin-top: -39px;
}

.termstxt {
  margin-left: 170px;
  margin-top: -41px !important;
  cursor: pointer;
  color: #3B4046 !important;
  position: absolute;


}

/* .inp:checked + input:after {
  accent-color: yellow;
  border: solid rgb(243, 243, 239) !important;
} */
/* .remforget input:checked  {
  accent-color: yellow;
} */
.remforget input[type=checkbox] {
  position: relative;
  border: 1px solid #000;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 .6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  opacity: .5;
}

.remforget input[type=checkbox]:hover {
  opacity: 1;
}

.remforget input[type=checkbox]:checked {
  background-color: var(--primary-bg-color);
  border: 1px #000 !important;
  opacity: 1;
}

.remforget input[type=checkbox]:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.newPasswordText {
  font-size: 24px;
  margin: 24px 0px 50px;
}

@keyframes lineMoving {
  0% {
    margin-left: -90px
  }

  100% {
    margin-left: 170px
  }
}


/* /////////////////////////////////////////////////////////////////// */

@media only screen and (max-width: 1500px) {
  .BGImg2 img {
    width: 80%;
  }
}

@media only screen and (max-width: 1320px) {
  .BGImg2 img {
    width: 60%;
  }
}

@media only screen and (max-width: 1150px) {
  .BGImg2 img {
    width: 45%;
  }
}

@media only screen and (max-width: 1000px) {
  .BGImg2 img {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .registerBackground1 .BGImg1 {
    display: none;
  }

  .registerBackground1 {
    background-color: var(--primary-bg-color) !important;
    color: var(--primary-background-text-color);
  }
}

@media only screen and (max-width: 600px) {
  .registration-container1 {
    margin: auto !important;
    width: 100%;
  }
}













/* Login page new css */

.hirewinglogologin {
  width: 150px;
  height: 58px;
  margin-top: 7%
}

.login-page__form h1 {
  margin: 0 0 59px;
  font-size: 30px;
}

.rembembermeSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}


.topLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.topLinks a {
  margin: 40px;
  text-decoration: none;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.m-2 {
  margin: 10px;
}

.text-center {
  text-align: center;
}

.tab {
  margin: 20px;
  text-decoration: none;
  color: black;
  width: 80px;
  cursor: pointer;
  border-bottom: 3px solid white;
}

.tab.active {
  color: var(--primary-bg-color);
  border-bottom: 3px solid var(--primary-bg-color);
}

.justify-content-start {
  justify-content: flex-start;
}

.flex-column {
  flex-direction: column;
}

.text-left {
  text-align: left;
}

.eyeicon {
  position: absolute;
  top: -35px;
  left: 200px;
  width: 30px;
}

.ml-2 {
  margin-left: 17px !important;
  margin-right: 53px !important;

}

.linewidth {
  width: 100%;
  margin-left: 0px !important;
  height: 50px;
}

.remforget {
  display: flex;
  margin-left: 5px;
  align-items: center !important;
}

.rembtext:hover {
  font-weight: bolder !important;
}

.btnSection .btn-submitlogin {
  width: 190px;
  height: 48px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  color: var(--primary-background-text-color) !important;
  background-color: var(--primary-bg-color) !important;
  border: 0;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize !important;

}

.btn-submitlogin:hover {
  background-color: var(--primary-background-text-color)!important;
  color: var(--primary-bg-color) !important;

}

.linktohome {
  display: block;
  margin-top: 20px;
  font-size: 20px;
  color: #3B4046;
  margin-left: 0px !important;
}

.justify-content-center {
  justify-content: center;
}

.forgotlink {
  text-decoration: none;
  color: black;
}

.forgotlink:hover {
  font-weight: bolder !important;
}

.activeTab {
  color: var(--primary-bg-color) !important;
}

.btnSection {
  text-align: center;
  width: 100%;
  /* margin-bottom: 21px; */
  margin-top: 51px;
}

.accounttxt {
  margin-left: 50px;
  margin-top: 15px;
}

.signuptxt {
  margin-left: 20px !important;
  margin-top: 13px !important;
  cursor: pointer;
}

.signtxt {
  margin-left: 30px;
  margin-top: 35px;
  margin-bottom: -15px;
}

.privacytxt {
  margin-left: 30px;
  cursor: pointer;
  color: #3B4046 !important;
}

.andtxt {
  margin-left: 137px;
  margin-top: -39px;
}

.termstxt {
  margin-left: 170px;
  margin-top: -41px !important;
  cursor: pointer;
  color: #3B4046 !important;
  position: absolute;
}

@keyframes lineMoving {
  0% {
    margin-left: -90px
  }

  100% {
    margin-left: 170px
  }
}


/* /////////////////////////////////////////////////////////////////// */

.login-page {
  height: 100vh;
  margin: 0px;
  padding: 0px;
  position: fixed;
  display: flex;
  width: 100%;
}

.login-page__left-container {
  flex: 3;
  background-color: #E4E4E4 !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.login-page__right-section {
  flex: 1.2;
  background-color: var(--primary-bg-color);
  display: flex;
  align-items: center;
  z-index: 1000;
  color: var(--primary-background-text-color);
}

.login-page__form {
  transform: translateX(-50%);
  background-color: #FFFFFF;
  border-radius: 44px;
  box-shadow: 0px 3px 6px #00000029;
  max-height: 646px;
  min-width: 350px;
  /* width: 100%;
  max-width: 450px; */
  min-height: 580px;
}

.login-page__form-heading {
  font-size: 24px;
  margin: 24px 0 50px 0;
  color: var(--primary-bg-color);
  

}

.login-page__user-icon {
  height: 100px;
  width: 100px;
  margin-top: 50px;
}

.login-page__platform-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.login-page__platform-info-logo {
  position: relative;
  top: 60px;
  left: 60px;
}

.login-page__platform-info-logo img {
  width: 356px;
  height: 110px;
}

.login-page__platform-info-heading {
  position: relative;
  top: 90px;
  left: 60px;
  text-align: left;
  font-size: 49px;
  color: #3B4046;
}

.login-page__platform-info-subtext {
  position: relative;
  top: 110px;
  left: 60px;
  width: 769px;
  height: 117px;
  text-align: left;
  font-size: 30px;
  color: #919191;
}

.login-page__platform-info-illustration {
  margin-top: auto;
}

.login-page__platform-info-illustration img {
  width: calc(100% - 250px);
}

.login-page__form-inputs {
  margin: 72px 22px 0 22px;
}

.login-page .linktologin {
  margin-bottom: 15px;
}

.login-page__icon-1 {
  position: absolute;
  top: 1%;
  left: 1%;
}

.login-page__icon-2 {
  position: absolute;
  top: 5%;
  left: 67%;
}

.login-page__icon-3 {
  position: absolute;
  bottom: 0;
  left: 80%;
}

.login-page__icon-4 {
  position: absolute;
  top: 21%;
  left: 34%;
  height: 50px;
}

.login-page__icon-5 {
  position: absolute;
  top: 55%;
  left: 53%;
  height: 50px;
}

.login-page__mobile-section {
  display: none;
}

.terms-conditions {
  width: 100%;
}

.terms-conditions a {
  color: #3C3C3C;
}

.terms-conditions p {
  margin-bottom: 5px;
  margin-top: 25px;
}

.terms-conditions h4 {
  margin-top: 0;
  color: #3C3C3C;
}

@media only screen and (min-width: 1800px) {

  .login-page__form {
    width: 70%;
  }
}

/* For screens max-width 1800px */
@media only screen and (max-width: 1800px) {

  .login-page__form {
    width: 70%;
  }

  .login-page__platform-info-logo {
    position: relative;
    top: 60px;
    left: 50px;
  }

  .login-page__platform-info-heading {
    font-size: 32px;
    left: 50px;
  }

  .login-page__platform-info-logo img {
    width: 270px;
    height: 90px;
  }

  .login-page__platform-info-subtext {
    font-size: 23px;
    width: 650px;
    left: 50px;
  }

  .login-page__user-icon {
    height: 100px;
    width: 100px;
    margin-top: 50px;
  }

  .login-page .btn-submitlogin {
    /* width: 150px;
    height: 48px;
    font-size: 16px;
    font-weight: 500; */
  }
}

/* For screens max-width 1350px */
@media only screen and (max-width: 1350px) {

  .login-page__form {
    min-height: auto;
  }

  .login-page__form-heading {
    font-size: 1.5rem;
  }

  .login-page__platform-info-heading {
    font-size: 30px;
    left: 45px;
  }

  .login-page__platform-info-logo img {
    width: 260px;
    height: 85px;
  }

  .login-page__platform-info-subtext {
    font-size: 20px;
    width: 500px;
    left: 45px;
  }

  .login-page__user-icon {
    height: 90px;
    width: 90px;
    padding-top: 35px;
  }

  .login-page__platform-info-logo {
    position: relative;
    top: 60px;
    left: 45px;
  }

  .login-page .btn-submitlogin {
    width: 170px;
    height: 35px;
    font-size: 16px;
  }

  .login-page__form-inputs {
    margin: 3% 7% !important
  }

  .signtxt {
    margin-top: 20px;
  }

  .btnSection {
    margin-top: 0;
  }

  .login-page .btn-submitlogin {
    width: 170px;
    height: 35px;
    font-size: 16px;
  }
}

/* For screens max-width 1150px */
@media only screen and (max-width: 1150px) {
  .login-page__form-heading {
    font-size: 1.4rem;
  }

  .login-page__platform-info-heading {
    font-size: 27px;
    left: 40px;
  }

  .login-page__platform-info-logo img {
    width: 240px;
    height: 80px;
  }

  .login-page__platform-info-subtext {
    font-size: 17px;
    width: 450px;
    left: 40px;
  }

  .login-page__user-icon {
    height: 80px;
    width: 80px;
    padding-top: 30px;
  }

  .login-page__platform-info-logo {
    position: relative;
    top: 60px;
    left: 40px;
  }

  .login-page .btn-submitlogin {
    width: 170px;
    height: 35px;
    font-size: 16px;
  }

  .login-page__form-inputs {
    margin: 3% 7% !important
  }

  .signtxt {
    margin-top: 20px;
  }

  .btnSection {
    margin-top: 0;
  }

  .signuptxt {
    margin-bottom: 0;
  }

  .accounttxt {
    margin-bottom: 0;
  }

}

/* For screens max-width 1000px */
@media only screen and (max-width: 1000px) {

  .login-page__form-heading {
    font-size: 1.2rem;
    margin: 5px 0;
  }

  .login-page__platform-info-heading {
    font-size: 18px;
    left: 35px;
  }

  .login-page__platform-info-logo img {
    width: 200px;
    height: 70px;
  }

  .login-page__platform-info-subtext {
    font-size: 16px;
    width: 340px;
    left: 35px;
  }

  .login-page__user-icon {
    height: 70px;
    width: 70px;
    padding-top: 30px;
  }

  .login-page .btn-submitlogin {
    width: 170px;
    height: 35px;
    font-size: 16px;
  }

  .login-page__form-inputs {
    margin: 3% 7% !important
  }

  .signtxt {
    margin-top: 20px;
  }

  .btnSection {
    margin-top: 0;
  }
}

@media only screen and (max-width: 900px) {
  .login-page__form {
    margin: 30px;
    width: 70%;
  }

  .login-page__form-heading {
    font-size: 1.2rem;
    margin: 5px 0;
  }

  .login-page__left-container {
    display: flex;
    justify-content: center;
  }

  .login-page__platform-info-heading {
    font-size: 16px;
    left: 20px;
  }

  .login-page__platform-info-logo img {
    width: 200px;
    height: 70px;
  }

  .login-page__platform-info-subtext {
    font-size: 14px;
    width: 300px;
    left: 20px;
  }

  .login-page__user-icon {
    height: 70px;
    width: 70px;
    padding-top: 35px;
  }

  .login-page__platform-info-logo {
    left: 20px;
  }

  .login-page__platform-info-illustration img {
    width: 70%;
  }

}

/* For screens max-width 768px */
@media only screen and (max-width: 768px) {

  .login-page__left-container {
    display: none;
  }

  .login-page__form {
    margin-left: 0;
    transform: none;
  }

  .login-page__right-section {
    width: 100%;
    background-color: #E4E4E4 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .login-page__form-heading {
    font-size: 1.2rem;
    margin: 5px 10px;
  }

  .login-page__platform-info {
    display: none;
  }

  .login-page__user-icon {
    height: 60px;
    width: 60px;
    padding-top: 30px;
  }

  .login-page__platform-info-logo-mobile img {
    height: 70px;
  }

  .login-page__platform-info-heading-mobile {
    margin-top: 20px;
    font-size: 30px;
    color: #3B4046;
  }

  .login-page__platform-info-subtext-mobile {
    text-align: center;
    font-size: 20px;
    color: #919191;
    margin: 20px 12%;
  }

  .login-page__mobile-section {
    display: block;
    text-align: center;
    margin-top: 30px;
  }

  .login-page {
    background-color: #E4E4E4;
    height: auto;
    position: static;
    min-height: 100vh;
  }

  .login-page__form {
    margin: 30px;
    width: 90%
  }

  .signtxt {
    margin-left: 0;
  }

  .privacytxt {
    margin-left: 0;
  }

}

@media only screen and (max-width: 600px) {
  .login-page__form {
    margin: 30px;
    width: 90%;
    min-width: 200px;
  }
}