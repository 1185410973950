.Addicon {
  display: inline-flex;
  align-items: center;
  cursor: pointer !important;
  justify-content: flex-end !important;
  margin-left: -25 px;
}

.dynatablebodytextemail {
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  color: #000000 !important;
  opacity: 100% !important;
  font: 14px "Roboto" !important;
}
.NameCapitalise {
  text-transform: capitalize;
}