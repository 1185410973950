.addusersection {
  cursor: pointer !important;
  right: 64px;
  top: 190px;
  position: absolute;
}

.addnewusertext {
  margin-top: 6px;
  font: normal normal normal 18px "Roboto";
  letter-spacing: 0px;
  color: #3B4046;
  opacity: 1;
}

.addnewusertext:hover {
  color: var(--primary-bg-color) !important;
}

.userimgposition {
  margin-top: 50px !important;
}

#radiobtn {
  margin-top: -25px;
}

.savebtnposition {
  margin-left: 690px;
  margin-top: 75px;
}

.usersavebtn {
  background-color: var(--primary-bg-color) !important;
  width: 6rem;
  /* height: 37px; */
  font-size: 14px !important;
  border-radius: 10px !important;
  color: var(--primary-background-text-color) !important;
  text-transform: capitalize !important;
}
.usersavebtn:hover {
  font-weight: bolder !important;
  background-color: var(--primary-background-text-color)!important ;
  color: var(--primary-bg-color) !important;
}
.sidebar{
  overflow: auto;
}

.uploadSection {
  display: block !important;
  padding: 18px !important;
  box-shadow: none !important;
  border: none;
  border-radius: 11px !important;
  text-transform: inherit !important;
  font: normal normal normal 18px/24px "Roboto" !important;
  color: #9e9e9e !important;
  width: 40% !important;
  margin: 0 auto 18px !important;
  background-color: var(--secondary-bg-color)!important;
  cursor: pointer !important;
}

.uploadSection span:first-child {
  font: normal normal bold 18px/24px "Roboto" !important;
  color: var(--primary-bg-color);
}

.uploadSection span+span {
  display: none;
}

.listofUploads {
  list-style: none;
  text-align: right;
  font-size: 15px;
  height: 250px;
  overflow-y: auto;
  padding: 0;
  border: 1px solid #efefef;
}

.listofUploads li {
  line-height: 2.4;
  display: flex;
  align-items: baseline;
  margin: 2px 0;
  background-color: #fff;
  padding: 0 25px;
}

.listofUploads li:nth-child(even) {
  background-color: #f5f5f5;
}

.listofUploads li span svg {
  color: red;
  font-size: 17px;
  vertical-align: middle;
  padding: 8px;
  cursor: pointer;
}

.listofUploads li p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 85%;
  text-align: left;
}

.imgBox {
  opacity: 1;
  position: fixed;
  bottom: 0;
  z-index: -1;
}

#radiobtn .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: var(--primary-bg-color) !important;
}

#adduserfield {
  text-align: left;
  padding-left: 10px;
}

.boldlabeluser label {
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #262626 !important;
  background: #ffff;
}

.selectOptions {
  display: block !important;
  padding: 5px 15px !important;
}

.applicationfieldcss {
  min-height: 53px;
  padding-left: 10px !important;
}

.reduceuploadSize {
  display: flex;
  align-items: baseline;
  margin-top: 14px !important;
}

.reduceuploadSize img {
  width: 33px;
}

#customerUser aside.active {
  width: 150px;
  height: 150px;
  background: #fff;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 5px #bebebe;
  border: 2px solid #fff;
}

#customerUser aside img {
  display: block;
  margin: 0 auto 26px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.groupfields .userProfileicon{
  text-align: center;
  margin-top: -30px !important;
}

.d-none{
  display: none;
}

.groupfields .MuiInputBase-root{
  min-height: 55px !important;
  padding: 7px 10px 0!important;
}




#customerUser .searchdivcss{
  order: 2;
  text-align: -webkit-right;
}

#customerUser .actionDropdowncss{
  order: 1;
}

/* #customerUser .headertext p{
  transform: matrix(1, 0, 0, 1, 0, 0);
  text-align: left;
  font-size: 20px !important;
  font-family: "Roboto" !important;
  font-weight: 700 !important;
  letter-spacing: 0.7px;
  color: var(--primary-bg-color) !important;
  opacity: 1
} */

#customerUser .boldSelectlabel {
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #262626 !important;
  background: #ffff;
}