.innerdiv {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
  /* margin-left: -40px; */
}

.cardPosition {
  width: 200px !important;
  height: 160px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 6px !important;
  cursor: pointer;
}


.rightnavsearchbar {
  margin-top: -25px !important;
  margin-left: 386px;
  border-radius: 50px !important;
  background: #FAFAFA !important;
  border: 1px solid #d1d1d1 !important;
  width: 224px;
  height: 40px !important;
  transition: all 0.2s ease-in-out !important;
}


/* .rightnavsearchbar:hover {
  width: 263px;
  height: 50px;
} */

.rightnavsearchbar:hover .MuiInputBase-input::placeholder,
.rightnavsearchbar .MuiInputBase-input:active::placeholder,
.rightnavsearchbar .MuiInputBase-input:focus::placeholder {
  color: #000 !important;
}

.pagination-barbtn {
  position: absolute;
  margin-left: 480px;
  margin-top: -50px !important;
}

.resbtn {
  position: relative;
  margin-top: 160px !important;
  margin-right: 690px;
  width: 71px;
  height: 26px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  font-size: 12px !important;
  letter-spacing: var(--unnamed-character-spacing-0-7);
  color: var(--unnamed-color-3b4046);
  text-align: left;
  font: normal normal medium 20px/27px "Roboto ";
  letter-spacing: 0.7px;
  color: #3B4046 !important;
  opacity: 1;
}

.resultHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 10px;

}

.resultHeader {
  font: normal normal normal 20px/27px "Roboto";
  letter-spacing: 0.7px;
  color: #3E3E3E;
  opacity: 1;
}

.innerdiv aside {
  background: #D9D9D9;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  width: 235px;
  /* cursor: pointer !important; */
  text-align: left;
  height: 155px;
  padding-bottom: 11px;
  /* margin: 15px; */
}

.innerdiv aside>div {
  font-weight: bold;
  font-size: 17px;
  padding-top: 10px;
  padding-left: 13px;
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.innerdiv aside>div h6 .hghghgj {
  color: #fff !important;
}

.innerdiv aside>div+div {
  background: #fff;
  border-radius: 6px;
  padding-right: 10px;
  height: 111px;
}

.innerdiv aside>div+div p {
  margin: 0;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  align-items: center;
}

.innerdiv aside>div+div p svg {
  margin-right: 10px;
}


.innerdiv aside>div+div p:first-child {
  font-weight: 400;
  font-size: 16px;
}

.innerdiv aside>div+div p:last-child {
  padding-bottom: 13px;
}

.innerdiv aside>div+div p:first-child {
  display: flex;
  height: 20px;
  justify-content: space-between;
}

.mathCards {
  display: flex;
  justify-content: flex-end;
  /* padding: 7px 12px 12px; */
  margin-top: -19px;
  /* z-index: 1; */
  gap: 10px;
}

.innerdiv aside.active>div+div {
  background: var(--primary-bg-color);
  color: var(--primary-background-text-color) !important;
}

.innerdiv aside.active>div[title] {
  color: #fff;
}

.innerdiv aside>div[title] {
  padding-right: 10px;
}

.resultHeader .pagination-container .pagination-item.selected {
  background-color: none;
  font-weight: bold;
  font-size: 15px;
}

.resultHeader .pagination-container .pagination-item {
  padding: 0 12px;
  margin: auto 0Gpx;
  font-size: 13px;
  min-width: 11px;
}

.mathCards>div>span {
  color: #3B4046 !important;
}

.innerdiv .MuiSvgIcon-root {
  font-size: 1.2rem;
  margin-left: -10px;
  color: #3E3E3E !important;
}

.innerdiv .active .MuiSvgIcon-root {
  font-size: 1.2rem;
  margin-left: -10px;
  color: var(--primary-background-text-color) !important;
}

.innerdiv aside.active {
  background: var(--secondary-bg-color);
}

aside.active>div.cardbgcolor {
  color:var(--secondary-background-text-color) !important;
}

.mathCards div {
  width: 38px;
  margin: -6px;
}

.mathCards span {
  font-size: 8px;
  font-weight: 900;
  letter-spacing: -1px;
  padding-top: 8px;
  padding-left: 4px;
}

.cardsforassjobs p {
  display: flex !important;
  justify-content: left !important;
  padding-top: 5px !important;
}

.cardsforassjobs svg {
  margin-top: -5px;
  margin-right: 3px;
}

/* .cardsforassjobs p {
  padding-left: 12px !important;
} */

.resumecount{
text-align: center;
font: normal normal normal 12px/18px "Poppins" !important;
font-family: "poppins" !important;
letter-spacing: 0px;
opacity: 1;
color: #3E3E3E !important;
}
.resumecount{
text-align: center;
font: normal normal normal 12px/18px "Poppins" !important;
font-family: "poppins" !important;
letter-spacing: 0px;
color: #3E3E3E !important;
opacity: 1;
}
.active .resumecount{
text-align: center;
font: normal normal normal 12px/18px "Poppins" !important;
font-family: "poppins" !important;
letter-spacing: 0px;
color: var(--primary-background-text-color) !important;
opacity: 1;
}
/* .MuiSvgIcon-root{
  color:var(--primary-background-text-color) !important ;
} */
.cardcheckbox {
  left: 94%;
  margin-top: -24px !important;
}
.cardcheckboxjobs {
  left: 92%;
  margin-top: -50px !important;
}
.aiCardcheckbox {
  left: 94%;
  margin-top: 8px !important;
}
.cardcheckbox.MuiCheckbox-root, 
.aiCardcheckbox.MuiCheckbox-root, 
.cardcheckboxjobs.MuiCheckbox-root, 
.cardcheckboxActive.MuiCheckbox-root, 
.cardcheckboxActivejobs.MuiCheckbox-root, 
.checkboxSingledoc.MuiCheckbox-root {
  color: #454545 !important;
}

.cardcheckboxActive {
  left: 95%;
  margin-top: 32px !important;
}

.checkboxSingledoc{
  left: 94%;
  margin-top: 8px !important;
}

.cardcheckboxActivejobs {
  left: 94%;
  margin-top: -65px !important;
}

.resumepdficon {
  height: 20px;
  width: 20px;
  margin: 0px !important;
}

.resumeTablediv {
  position: absolute;
  /* box-shadow: 0px 3px 6px #00000029; */
  height: 162px !important;
  width: 761px;
  left: 0px;
  border-radius: 0px 0px 0px 0px !important;
  border: 1px solid #00000075;
  overflow-y: auto !important;
}
.resumeTabledivJobs {
  position: absolute;
  /* box-shadow: 0px 3px 6px #00000029; */
  height: 162px !important;
  width: 761px;
  left: 0px;
  border-radius: 0px 0px 0px 0px !important;
  border: 1px solid #00000075;
  overflow-y: auto !important;
}

 aside.resumesCardCSS {
  height: 315px;
  border: 1px solid #00000075;
  border-top: none !important;
}
 aside.resumesCardCSS.active {
  height: 323px;
}

 aside.resumesCardCSS>div+div {
  height: 123px;
  border-radius: 8px 8px 0px 0px ;
  position: relative;
}


.resumeTablediv .nodatasection img{ 
  height: 30px;
}
.resumeTablediv .nodatasection { 
  height: 12vh;
  font-size: 12px;
}

.resumeTablediv .customizedTable th ,
.resumeTablediv .customizedTable td {
  height: 20px !important;
  align-content: center;
}

.doctablebodytext {
  font-family: "Roboto" !important;
    font-size: 12px !important;
    font-weight: 500 !important;
     text-transform: capitalize;
}
.doctableheadtext {
  font-family: "Roboto" !important;
    font-size: 12px !important;
    font-weight: 500 !important;
     text-transform: capitalize;
}

.resumeTablediv .customizedTable .MuiTableCell-root  { /*.MuiTableCell-root.MuiTableCell-head*/
  padding: 5px;
}
.resumeTabledivJobs .customizedTable .MuiTableCell-root  { /*.MuiTableCell-root.MuiTableCell-head*/
  padding: 5px;
}

.resumeTablediv .viewicon svg,
.resumeTabledivJobs .viewicon svg {
  height: 14px;
  margin-top: -7px;
}
.resumeTablediv .viewicon:hover:after,
.resumeTabledivJobs .viewicon:hover:after {
  font-size: 7px;
  top: 17px !important;
}

#associateResumes .sidebar .closeicon {
  margin: 25px 0px 0px 0px !important;
}

.hideBorder {
  background: #ffffff;
  width: 235px;
  margin-top: 125px;
  z-index: 2 !important;
  position: absolute !important;
  left: 0px;
  height: 25px !important;
}
.hideBorderActive {
  background: var(--primary-bg-color) !important;
  width: 235px;
  margin-top: 125px;
  z-index: 2 !important;
  position: absolute !important;
  left: 0px;
  height: 25px;
  color: var(--primary-background-text-color) !important;
}
.hideBorderjobs {
  background: #ffffff;
  width: 212px;
  margin-top: 125px;
  z-index: 2 !important;
  position: absolute !important;
  left: 0px;
  height: 8px !important;
}
.hideBorderActivejobs {
  background: var(--primary-bg-color) !important;
  width: 212px;
  margin-top: 116px;
  z-index: 2 !important;
  position: absolute !important;
  left: 0px;
  height: 25px !important;
  color: var(--primary-background-text-color) !important;
}

.resumesCardCSS .cardbgcolor {
  background: var(--secondary-bg-color) !important;
  color: var(--secondary-background-text-color) !important;
  border-radius: 8px 8px 0px 0px;
}

.uploadedDate {
text-align: center;
font: italic normal normal 12px/18px "Poppins";
letter-spacing: 0px;
color: var(--primary-background-text-color) !important;
opacity: 1;
}
.active .uploadedDate {
text-align: center;
font: italic normal normal 12px/18px "Poppins";
letter-spacing: 0px;
color: var(--primary-background-text-color) !important;
opacity: 1;
}

.uploadedDate {
text-align: center;
font: italic normal normal 12px/18px "Poppins";
letter-spacing: 0px;
color: #3E3E3E !important;
opacity: 1;
}

.active .resumeTablediv {
  background: var(--primary-bg-color);
  margin-right: 15px !important;
  color: var(--primary-background-text-color) !important;
}
.active .resumeTabledivJobs {
  background: var(--primary-bg-color);
  margin-right: 15px !important;
  width: 771px;
  color: var(--primary-background-text-color) !important;
}

.active .cardsforassjobs{
  height: 112px;
  border-radius: 8px 8px 8px 8px;
  position: relative;
}

.active .resumeTablediv .MuiTableContainer-root {
  width: 98.5% !important;
}
.active .resumeTabledivJobs .MuiTableContainer-root {
  width: 98.5% !important;
}