.thumbnailSection{
    display: flex;
    justify-items: baseline;
    position: relative;
}

.templateThumbnail {
    top: 422px;
    left: 376px;
    width: 100%; 
    /* 256px */
    height: 350px;
    background: #FFFFFF 0% 0% no-repeat border-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding: 14px 18px;
    box-sizing: border-box;
}

.templateContent{
    position: absolute;
    width: 100%;
    /* calc(100% + 6%); */
    -moz-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    transform-origin: top left;
    height: 447px;
    overflow: hidden;
}

.templateActions{
    list-style: none;
    padding: 0;
    margin: 0;
}

.templateActions li{
    width: 56px;
    height: 50px;
    background: #EDEDED 0% 0% no-repeat border-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.templateActions li:hover{
    background-color: var(--primary-bg-color);
    cursor: pointer;
    color: var(--primary-background-text-color);
}

.mailSection.rightsidebarbtn .sidebar{
    width: 987px!important;  /* 1085px */
}

.mailSection.rightsidebarbtn .sidebar .contentSection {
    padding: 0px 33px 20px;
}



.mailboldSelectlabel {
    top: 0;
    left: 0;
    position: absolute !important;
    background: #fff !important;
    z-index: 1;
    padding: 0 9px;
    font-weight: 500 !important;
    font-size: 18px !important;
    color: #262626 !important;
    border-color: #e0e0e0 !important
  }

.dropdownSection{
    position: relative;
    text-align: left;
}

.mailSection.rightsidebarbtn .sidebar{
    overflow-y: auto ;
    overflow-x: hidden !important;
}

.cancelsavebutton {
    padding: 40px 0;
    float: right;
}

.emailsavebtn {
    background-color: var(--primary-bg-color) !important;
    width: 6rem;
    height: 40px !important;
    font-size: 14px !important;
    border-radius: 10px !important;
    color: var(--primary-background-text-color) !important;
    text-transform: capitalize !important;
  }
  .emailsavebtn:hover {
    font-weight: bolder !important;
    background-color:#3B4046 !important;
    color: var(--primary-bg-color) !important;
    background-color:var(--primary-background-text-color) !important;
  }

  .addvendorsection {
    cursor: pointer !important;
    right: 64px ;
    top: 190px ;
    position: absolute;
  }
  
  .mailboldlabel label {
    font-weight: 500 !important;
    font-size: 16.8px !important;
    color: #000000 !important;
    margin-left: -3px;
  }

  /* .mailSection .headertext p{
    transform: matrix(1, 0, 0, 1, 0, 0);
    text-align: left;
    font-size: 20px !important;
    font-family: "Roboto" !important;
    font-weight: 700 !important;
    letter-spacing: 0.7px;
    color: var(--primary-bg-color) !important;
    opacity: 1
  } */

  .sidebar .mailcancelbtn a {
    color: red !important;
    font-size: 14px;
  }
  