.registerBackground1{
  /* background-image: url("../../../../public/assests/backgroundimg.png"); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width:75%;
  height:100vh;
  margin:0px;
  padding:0px;
  position: fixed;
  display:flex;
  justify-content: space-between;
  align-items: center;
  /* padding:30px; */
}
.registerBackground p{
  color:black;
}
.registerBackground1 .BGImg2{
  left: 20%;
  position: relative;
  /* top: 25%; */
}
.registration-container2{
  /* position: absolute;
  right: -15% ;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 44px;
  box-shadow: 0px 3px 6px #00000029;
  margin-right: 62px;
  max-height: 646px;
  min-width: 350px;
  width: 100%;
  max-width: 450px;
  min-height: 610px; */
  transform: translateX(-50%);
  background-color: #FFFFFF;
  border-radius: 44px;
  box-shadow: 0px 3px 6px #00000029;
  max-height: 646px;
  min-width: 350px;
  min-height: 580px;
  position: relative;
  left: 96%;

}
.topLinks{
  display: flex;
  justify-content: center;
  align-items: center;
  height:80px;
}
.topLinks a{
  margin:40px;
  text-decoration: none;
}
.d-flex{
  display: flex;
}
.align-items-center{
  align-items: center;
}
.m-2{
  margin:15px;
}
.text-center{
  text-align: center;
}
.tab{
  margin:20px;
  text-decoration: none;
  color:black;
  width:80px;
  cursor: pointer;
  border-bottom: 3px solid white;
}
.tab.active{
  color:var(--primary-bg-color);
  border-bottom: 3px solid var(--primary-bg-color);
}
.justify-content-start{
  justify-content: flex-start;
}
.flex-column{
  flex-direction: column;
}
.text-left{
  text-align: left;
}
.eyeicon{
  position: absolute;
  top:-35px;
  left:200px;
  width:30px;
}
.ml-2{
  margin-left: 70px;
  margin-right:70px;
}
.btn-submit2{
  width: 190px;
  height: 48px;
  font-family: "Roboto";
  font-weight: 500;
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-background-text-color) !important;
  box-shadow: 0 3px 6px #00000029;
  border:0;
  border-radius: 10px !important;
  cursor:pointer;
  font-size:16px !important;
  /* margin-left: 27px !important; */
  /* margin-top: 60px !important; */
}
.btn-submit2:hover{
  background-color: var(--secondary-bg-color)!important;
  color:var(--primary-bg-color) !important;
}
.link{
  display: block;
  margin-top: 15px;
  font-size:18px;
  color:#3B4046;
}
.justify-content-center{
  justify-content: center;
}
.forgotlink2{
  text-decoration: none;
  color:black;
  background-color: white;
  border:0;
  cursor:pointer;
  font-weight: bold;
}

.placeHolderemail{
  width: 125% !important;
  margin-left: -26px !important;
}
.hirewinglogoforgotpassword {         
  width: 150px;
  height: 58px;
  margin-top: 36%;
}
.enteremail{
  font-size: 36px !important;
}
/* /////////////////////////////////////////////////////////////////// */

@media only screen and (max-width: 1500px)  {
  .BGImg2 img{
  width: 80%;
}
}
@media only screen and (max-width: 1320px)  {
  .BGImg2 img{
  width: 60%;
}
}
@media only screen and (max-width: 1150px)  {
  .BGImg2 img{
  width: 45%;
}
}
@media only screen and (max-width: 1000px)  {
  .BGImg2 img{
  display: none;
}
}
@media only screen and (max-width: 768px)  {
.registerBackground1 .BGImg1{
  display:none;
}
.registerBackground1 {
  background-color:var(--primary-bg-color) !important;
  color: var(--primary-background-text-color) !important;
}
}
@media only screen and (max-width: 600px)  {
.registration-container2{
margin:auto !important;
width: 100%;
}
}