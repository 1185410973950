.emailkeys {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0 !important;
  color: #6A6A6A;
}

.emailkeys li {
  list-style: none;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 400px;
  align-items: center;
}

.customheading {
  display: flex;
  align-items: flex-start;
  padding-left: 0 !important;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
}

.generatetemplateSms {
  width: 210px !important;
  margin-top: 350px;
  background-color:  var(--primary-bg-color)!important; 
  color:var(--primary-background-text-color) !important;
  border-radius: 15px !important;
  height: 37px !important;
  /* padding: 0 23px !important; */
  text-transform: capitalize !important;
  margin-left: 330px !important;
  /* margin-bottom: 15px !important; */
  top: 20px;
  border-radius: 10px !important;
  font-size: 16px !important;
  width: 180px;
}
.copytemplate {
  background-color:  var(--primary-bg-color)!important; 
  color:var(--primary-background-text-color) !important;
  border-radius: 10px !important;
  height: 37px !important;
  padding: 0 23px !important;
  text-transform: capitalize !important;
  margin-left: 330px !important;
  /* margin-bottom: 15px !important; */
  top: 20px;
}

.generatetemplateSms:hover {
  background-color:var(--primary-background-text-color) !important;
  color:var(--primary-bg-color)!important;
  border-radius: 10px !important;
  height: 48px !important;
  /* padding: 0 23px !important; */
  text-transform: capitalize !important;
  font-size: 16px;
}
.copytemplate:hover {
  background-color: var(--primary-background-text-color) !important;
  color: var(--primary-bg-color) !important;
  border-radius: 10px !important;
  height: 48px !important;
  /* padding: 0 23px !important; */
  text-transform: capitalize !important;
  top: 20px;

}


#mailtemplatebody {
  min-height: calc(70vh - 50px);
  /* overflow: auto; */
}
#mailtemplatebody .MuiInputBase-input{
  padding-left: 10px;
}

.ringcentralBtn{
  background-color: var(--primary-bg-color) !important;
  color:var(--primary-background-text-color) !important ;
  border-radius: 10px !important;
  font-family: "Roboto" !important;
  font-weight: 500 !important;
  text-transform: capitalize;
 padding:16px !important;
}

.ringcentralBtn:hover{
  color: var(--primary-bg-color) !important;
  background-color: var(--primary-background-text-color) !important ;
  border-radius: 10px !important;
  font-family: "Roboto" !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}