 .customgroupButtons{
    width: 100%;
    text-align: center;
  }
  
  .customgroupButtons button{
    background: #ffffff;
    color: #000!important;
    border: none!important;
    text-transform: uppercase !important;
  }
  
  .customgroupButtons button.active, .customgroupButtons button:hover{
    background: var(--primary-bg-color);
    color: var(--primary-background-text-color) !important;
    font-weight: 600;
  }
  
  .customgroupButtons .MuiButtonGroup-contained{
    border-radius: 30px!important;
    overflow: hidden;
  }

  #dynamicTableSection > label{
    font-size: 18px;
    margin-bottom: 12px;
  }
  #dynamicTableSection > label:hover{
    font-size: 18px;
    margin-bottom: 12px;
    color: var(--primary-bg-color) !important;
  }
  
  #dynamicTableSection > label svg{
    margin-right: 6px;
  }

  #dynamicTableSection .MuiIconButton-root:hover, #dynamicTableSection .MuiIconButton-root:active,
#dynamicTableSection .MuiIconButton-root:focus, #dynamicTableSection .MuiIconButton-root:visited,
#dynamicTableSection .MuiIconButton-root.Mui-disabled{
  background: none!important;
  border-radius: 0!important;
}

#dynamicTableSection th{
    font-size: 16px !important;
  }
  #dynamicTableSection td{
    font-size: 14px !important;
    color: #000 !important;
    font-family: "Roboto" !important;
    font-weight: 400 !important;
  }

 