/* .registerBackground{
  background-image: url("../../../../public/assests/backgroundimg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width:75%;
  height:100vh;
  margin:0px;
  padding:0px;
  position: fixed;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding:30px;
}
.registerBackground p{
  color:black;
} */

.usericonlogin{
  height: 100px;
  width: 100px;
  margin-top: 20px;
}
/* .hirewinglogologin{
  width: 150px;
    height: 58px;
    margin-top: 18%
} */
.hirewinglogoresetpage {
  width: 150px;
  height: 58px;
  margin-top: 36%;
}
.registration-container22{
  position: absolute;   
  right: -15%;                
  width: 428.54px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 80px;
  box-shadow: 0px 3px 6px #00000029;
  height: 646px;
}
/* .registration-container{
  position: relative;
  left:300px;
  width:529.04px;
  height:604.82px;
  background-image: url("../../../../public/assests/signincurve.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
} */
.registerBackground-respass {
  background-repeat: no-repeat;
  width: 75%;
  height: 100vh;
  margin: 0px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 30px; */
  background-color: #E4E4E4 !important;
}
.registerBackground-respass .BGImg2respass{
  left: 20%;
  position: relative;
  /* top: 25%; */
}
.BGImg2respass img{
  width: 100%;
}
.BGImg1respass {
  display: flex;
  /* position: absolute; */
  top: 0%;
  align-items: flex-start;
  justify-content: inherit;
  width: 85%;
  position: absolute;
}

.registration-container2{
     position: absolute;
     left: 95.2%;
    /* right: -15% !important; */
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 44px;
    box-shadow: 0px 3px 6px #00000029;
    margin-right: 75px;
    margin-right: 62px;
    max-height: 646px;
    min-width: 350px;
    min-height: 605px;
    

  
   /* transform: translateX(-50%);
  background-color: #FFFFFF;
  border-radius: 44px;
  box-shadow: 0px 3px 6px #00000029;
  max-height: 646px;
  min-width: 350px;
  width: 100%;
  max-width: 450px;
  min-height: 580px;
  position: relative;
  left: 30%; */
  
}

.passcontent-section{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 margin-top: 30px;
}
.password-fieldcss{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.topLinks{
  display: flex;
  justify-content: center;
  align-items: center;
  height:80px;
}
.topLinks a{
  margin:40px;
  text-decoration: none;
}
.d-flex{
  display: flex;
}
.align-items-center{
  align-items: center;
}
.m-2{
  margin:20px;
}
.text-center{
  text-align: center;
}
.tab{
  margin:20px;
  text-decoration: none;
  color:black;
  width:80px;
  cursor: pointer;
  border-bottom: 3px solid white;
}
.tab.active{
  color:var(--primary-bg-color);
  border-bottom: 3px solid var(--primary-bg-color);
}
.justify-content-start{
  justify-content: flex-start;
}
.flex-column{
  flex-direction: column;
}
.text-left{
  text-align: left;
}
.eyeicon{
  position: absolute;
  top:-35px;
  left:200px;
  width:30px;
}
.ml-2{
  margin-left: 70px;
  margin-right:70px;
}
.btn-submitrespass.m-2{
  width: 190px;
  height: 48px;
  font-family: "Roboto";
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-background-text-color) !important;
  border:0;
  border-radius: 10px;
  cursor:pointer;
  font-weight: 500;
  font-size:16px;
  /* margin-left: 120px; */
  margin-top: 30px;
  box-shadow: 0 3px 6px #00000029;
}

.linktologin{
  display: block;
  margin-top:15px;
  font-size:18px;
  color:#3B4046;
}
.justify-content-center{
  justify-content: center;
}
.activeTab{
  color:var(--primary-bg-color)!important;
}
.resetbtn{
  margin-bottom: 0px !important;
}

/* ///////////////////////////////////////////////////////////////////////////// */

@media only screen and (max-width: 1500px)  {
    .BGImg2respass img{
    width: 80%;
  }
}
@media only screen and (max-width: 1320px)  {
    .BGImg2respass img{
    width: 60%;
  }
}
@media only screen and (max-width: 1150px)  {
    .BGImg2respass img{
    width: 45%;
  }
}
@media only screen and (max-width: 1000px)  {
    .BGImg2respass img{
    display: none;
  }
}
@media only screen and (max-width: 768px)  {
  .registerBackground-respass .BGImg1respass{
    display:none;
  }
  .registerBackground-respass {
    background-color:var(--primary-bg-color) !important;
    color: var(--primary-background-text-color) !important;
  }
}
@media only screen and (max-width: 600px)  {
.registration-container-resetpass{
  margin:auto !important;
  width: 100%;
}
}

@media only screen and (min-width: 1800px)  { 
  .btn-submitrespass.m-2 {
    margin-left: 2px;
  }

  .linktologin { 
    margin-left: 1px;
  }
}