.documents {
  margin-top: 2px;
  margin-left: 50px;

}

.documenttext {
  font-weight: 700;
}
.text-left{
  align-items: flex-start !important;
}
/* .dynatableheadtext {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  text-transform: capitalize;
} */

.postingdocumenttablecss {
  width: 1377px;
  margin-left: 50px;
}

/* .dynatablebodytext {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  text-transform: capitalize;
  color: #000000 !important;
  opacity: 100% !important;
  font: normal normal normal 17px/27px "poppins" !important;

} */

.documentposttime {
  color: #848484 !important;
  /* color: #000000 !important;
  opacity: 1 !important;
  font: normal normal normal 18px/27px "Poppins"!important;
  font-family: "Poppins"!important;
  font-weight: 400!important;
  text-transform:capitalize; */
}

.customizedTable td.documentposttime:last-child{
  padding-left: 0!important;
  padding-right: 0!important;
}

.listofUploads{
  list-style: none;
    text-align: right;
    font-size: 15px;
    height: 250px;
    overflow-y: auto;
    padding: 0;
    border: 1px solid #efefef;
}

.listofUploads li {
  line-height: 2.4;
  display: flex;
  align-items: baseline;
  margin: 2px 0;
  background-color: #fff;
  padding: 0 25px;
}

.listofUploads li:nth-child(even){
  background-color: #f5f5f5;
}

.listofUploads li span svg {
  color: red;
  font-size: 17px;
  vertical-align: middle;
  padding: 8px;
  cursor:pointer;
}

.listofUploads li p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 85%;
  text-align: left;
}
.adddocumentsection{
  cursor: pointer !important;
  position: absolute;
  right: 2.5%;
  top: 203px;
}

.addnewdocumenttext{
  margin-top: 6px;
  font: normal normal normal 20px/26px "Roboto";
letter-spacing: 0px;
color: #5D5D5D;
opacity: 1;
}

.addnewdocumenttext:hover{
  color: var(--primary-bg-color) !important;
}
.uploadfileicon{
  margin-top: 55px;
}
.appuploadresume {
  width: 340px;
  display: inline-block !important;
  padding: 18px 30px !important;
  box-shadow: none !important;
  border: 2px dashed var(--primary-bg-color)!important;
  border-radius: 10px !important;
  text-transform: inherit !important;
  font: normal normal normal 18px/24px "Roboto" !important;
  margin: 0 auto 20px !important;
  background-color: white !important;
  cursor: pointer !important;
  margin-top: -  30px !important;
  height: 64px !important;
}

.appuploadresume:hover {
  background-color: #000000 !important;
  display: none;
 border:2px solid #3B4046!important; 
}

.appuploadresume:hover span b:last-child{
  display: none;
}

.appuploadresume span:first-child {
  font-family: normal normal bold 18px "Roboto" !important;
  color: var(--primary-bg-color);
}

.appuploadresume span+span {
  display: none;
}
.applidocsupporttext {
  margin-top: -10px;
}

/* #pdfViewer .contentSection.pannelContent{
  height: inherit!important;
}

#pdfViewer .contentSection.pannelContent article{
  margin: 0;
} */

#documentViewer .sidebar{
width: 790px !important;
}

#documentViewer .contentSection.pannelContent{
  height: inherit!important;
}

#documentViewer .contentSection.pannelContent article{
  margin: 0;
}

.topBtns{
  position: absolute;
  bottom: 23% !important;
  left: 63%;
}