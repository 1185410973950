.emailkeys {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0 !important;
  color: #6A6A6A !important;
}

.emailkeys li {
  list-style: none;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 350px;
  align-items: center;
}

.customheading {
  display: flex;
  align-items: flex-start;
  padding-left: 0 !important;
  letter-spacing: 0px;
  color: #262626 !important;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
}

.generatetemplateEmail {
  /* width: 150px; */
  margin-top: 350px;
  background-color:  var(--primary-bg-color)!important; 
  color:var(--primary-background-text-color) !important;
  border-radius: 15px !important;
  height: 37px !important;
  padding: 0px 20px !important;
  text-transform: capitalize !important;
  margin-left: 330px !important;
  /* margin-bottom: 15px !important; */
  top: 20px;
  border-radius: 10px !important;
  font-size: 16px !important;
  max-width: 40% !important;
}
.copytemplate {
  background-color: var(--primary-bg-color)!important; 
  color: var(--primary-background-text-color) !important;
  border-radius: 10px !important;
  height: 37px !important;
  padding: 0 23px !important;
  text-transform: capitalize !important;
  margin-left: 330px !important;
  /* margin-bottom: 15px !important; */
  top: 20px;
}

.generatetemplateEmail:hover {
  background-color:var(--primary-background-text-color) !important;
  color: var(--primary-bg-color) !important;
  border-radius: 10px !important;
  height: 48px !important;
  /* padding: 0 23px !important; */
  text-transform: capitalize !important;
  font-size: 16px;
}
.copytemplate:hover {
  background-color:var(--primary-background-text-color) !important;
  color: var(--primary-bg-color) !important;
  border-radius: 10px !important;
  height: 48px !important;
  /* padding: 0 23px !important; */
  text-transform: capitalize !important;
  top: 20px;

}


#mailtemplatebody {
  min-height: calc(70vh - 50px);
  /* overflow: auto; */
}
#mailtemplatebody .MuiInputBase-input{
  padding-left: 10px;
}
