.customTooltip img {
    position: absolute;
    top: 16px;
    right: 14px;
    cursor: pointer
  }
  
  .customTooltip img + div{
    display: none;
  }
  
  .customTooltip img:hover + div{
    display: block;
    position: absolute;
    background: #fff;
    padding: 11px;
    margin-top: 9px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    line-height: 1.6;
    font-size: 15px;
    color: #8E8E8E;
  }